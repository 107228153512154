import React from 'react'

const withConfig = (WrappedComponent) => {
    return class extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                config: {
                    ...window.$config,
                    CONFIGURED_URLS: {
                        changePassword: `${window.$config.REACT_APP_OIDC_CHANGE_PASSWORD_URL}`,
                        newRegistration: `${window.$config.REACT_APP_OIDC_REGISTRATION_URL}`,
                        manageUsers: `${window.$config.REACT_APP_USER_MANAGEMENT_URL}`,
                    }
                }
            }
        }
        render() {
            return (
                <WrappedComponent config={this.state.config} {...this.props} />
            )
        }
    }
}

export default withConfig
