/* eslint-disable import/no-anonymous-default-export */
import React, { useCallback, useContext, useMemo } from 'react'
import { FaFileExport } from 'react-icons/fa'
import { Parser } from 'json2csv'
import { saveAs } from 'file-saver'
import { CurrentProcessContext } from '../wrappers/CurrentProcessContext'

import { DefaultTooltip } from './Tooltip'
import dayjs from 'dayjs'

const ExportButton = ({ data, view }) => {
    const { externalDataItems } = useContext(CurrentProcessContext)

    const csvData = useMemo(
        () =>
            [...(view === 'All' ? externalDataItems : data)].map((x) => {
                const item = { ...x.AttributeJSON, ...x }
                Object.keys(item).map((y) =>
                    y.includes('Date') && item[y]
                        ? (item[y] = dayjs(item[y]).format(
                              'MM/DD/YYYY HH:mm:ss A'
                          ))
                        : y
                )
                delete item.AttributeJSON
                return item
            }),
        [data, externalDataItems, view]
    )

    const downloadData = useCallback(() => {
        try {
            const parser = new Parser()
            let csv = parser.parse(csvData)
            const downloadName = `${dayjs()
                .format('YYYYMMDD')
                .toString()}${view}.csv`
            saveAs(new Blob([csv], { type: 'text/csv' }), downloadName)
        } catch (e) {
            console.log('csv export error:', e)
        }
    }, [csvData, view])

    return (
        <div className="controlButtonWrapper">
            <div
                className="is-size-65"
                onClick={() => {
                    downloadData()
                }}
                data-tip={`Export ${view} to CSV`}
                data-for={`export_${view}`}
            >
                <FaFileExport />
                <DefaultTooltip id={`export_${view}`} place={'top'} />
            </div>
        </div>
    )
}

export default ExportButton
