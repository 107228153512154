import React, { useContext, useCallback, useMemo } from 'react'
import LoadingSpinner from '../../../../elem/LoadingSpinner'
import { filterColors } from '../../../../../utils/StatusColors'

import { CurrentProcessContext } from '../../../../wrappers/CurrentProcessContext'
import { FilterContext } from '../../../../wrappers/FilterContext'

const ProcessSubTypeBar = () => {
    const {
        externalDataItems,
        processSubTypeColumn,
        processSubTypes,
    } = useContext(CurrentProcessContext)
    const {
        filters,
        setFilters,
        filters: { ProcessSubType: activeSubType },
    } = useContext(FilterContext)

    const handleInnerDivClick = useCallback(
        (subType) => {
            setFilters({ ...filters, ProcessSubType: subType })
        },
        [filters, setFilters]
    )

    const handleOuterDivClick = useCallback((e) => {
        if (
            !(
                e.target.className.includes('inner') ||
                e.target.localName === 'h6'
            )
        ) {
            setFilters({ ...filters, ProcessSubType: null })
        }
    }, [filters, setFilters])

    const subTypeData = useMemo(() => {
        if (
            processSubTypes &&
            processSubTypes.length &&
            externalDataItems &&
            externalDataItems.length
        ) {
            return processSubTypes.reduce((acc, curr) => {
                const subType = curr
                const count = externalDataItems
                    .map((x) => x.AttributeJSON)
                    .filter((x) => x[processSubTypeColumn] === subType).length
                return [
                    ...acc,
                    {
                        subType,
                        count,
                    },
                ]
            }, [])
        }
    }, [processSubTypes, externalDataItems])

    return (
        <div className="actionGroups processSubTypes">
            <div className={`group`} onClick={handleOuterDivClick}>
                <h5 className="title is-6">{'Workflow Sub-Types'}</h5>
                <div className="box">
                    <div className="outerBar">
                        {!processSubTypes.length ? (
                            <div className="spinnerContainer">
                                <LoadingSpinner size="2em" />
                            </div>
                        ) : (
                            subTypeData.map((subType, i) => {
                                return (
                                    <div
                                        className={`innerBar ${
                                            activeSubType
                                                ? activeSubType ===
                                                  subType.subType
                                                    ? 'selected'
                                                    : 'inactive'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            handleInnerDivClick(subType.subType)
                                        }
                                        key={`${i}`}
                                        style={{
                                            width: `${
                                                (1 / processSubTypes.length) *
                                                100
                                            }%`,
                                            backgroundColor: filterColors[i],
                                        }}
                                    >
                                        <h6 className="is-size-6 has-text-black total">
                                            {subType.count}
                                        </h6>

                                        <h6 className="is-size-7 has-text-black">
                                            {subType.subType}
                                        </h6>
                                    </div>
                                )
                            })
                        )}
                        {/* <div
                            className="innerBar inactive"
                            style={{
                                width: '100%',
                            }}
                        >
                            <h6 className="is-size-6 has-text-black total">
                                0
                            </h6>

                            <h6 className="is-size-7 has-text-black">None</h6>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProcessSubTypeBar
