import React, { useEffect, useState, useContext } from 'react'
import withConfig from '../../../wrappers/withConfig'
import toast from '../../../elem/Toast'
import { DateSelector } from '../../../elem/Selectors'
import { ConfirmModal } from '../../../elem/Modal'
import { FaEdit } from 'react-icons/fa'
import dayjs from 'dayjs'
import { APIRequestContext } from '../../../wrappers/APIRequestWithToken'
import LoadingSpinner from '../../../elem/LoadingSpinner'
import { setTimeToDefault } from '../../../../utils/formatDate'

const format = 'MM/DD/YYYY'
const dateToString = (date) =>
    date ? dayjs(date).format(format).toString() : null
const stringToDate = (date) => (date ? dayjs(date).toDate() : null)

const DateEditCell = ({
    value: initialValue,
    row,
    column,
    label,
    updateMyData,
    config,
}) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [value, setValue] = useState(stringToDate(initialValue))
    const [loading, setLoading] = useState(false)
    const [editing, setEditing] = useState(false)
    const [select, setSelect] = useState(false)

    const PUT = (edited) => {
        return {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(edited),
        }
    }

    const editDate = (dateObject) => {
        setLoading(true)
        authenticatedFetch(
            `${API_URL}/Process/editDataItem/${row.original.ExternalDataItemId}`,
            PUT(dateObject)
        )
            .then(async (response) => {
                if (response.ok) {
                    return response
                } else {
                    const error = await response.text()
                    setValue(stringToDate(initialValue))
                    throw new Error(error)
                }
            })
            .then(() => {
                updateMyData(row.index, column, dateToString(value))
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Workflow Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(() => {
                setLoading(false)
                setSelect(false)
                setEditing(false)
            })
    }

    const onChange = (e) => {
        setValue(e)
    }

    const onBlur = () => {
        setEditing(false)
    }

    const onSelect = (e) => {
        if (dateToString(e) !== dateToString(value)) {
            setSelect(e)
        }
    }

    const cancel = () => {
        setSelect(false)
        setValue(stringToDate(initialValue))
    }
    useEffect(() => {
        setValue(stringToDate(initialValue))
    }, [initialValue])

    return (
        <>
            {loading ? (
                <div className="spinnerContainer">
                    <LoadingSpinner />{' '}
                </div>
            ) : (
                <div
                    onClick={() => setEditing(true)}
                    className="tableCell editable"
                >
                    {editing ? (
                        <DateSelector
                            selected={value}
                            onChange={onChange}
                            onSelect={onSelect}
                            onBlur={onBlur}
                        />
                    ) : (
                        <span className="is-flex">
                            {dateToString(value)}{' '}
                            <FaEdit
                                className=""
                                style={{
                                    marginLeft: '5px',
                                    fontSize: 'x-small',
                                }}
                            />{' '}
                        </span>
                    )}
                    {select ? (
                        <ConfirmModal
                            confirmation={`Change ${label} of Record ${
                                row.original.ExternalDataItemId
                            } ${initialValue ? `from ${dateToString(initialValue)}` : ``} to ${dateToString(select)}?`}
                            cancel={cancel}
                            submit={() => {
                                column === `DueDate` 
                                ? editDate({
                                    StartDate: setTimeToDefault(
                                        row.original.StartDate
                                    ),
                                    DueDate: setTimeToDefault(value),
                                    ExternalDataItemId: row.original.ExternalDataItemId,
                                })
                                : editDate({
                                    StartDate: setTimeToDefault(
                                        row.original.StartDate
                                    ),
                                    DateComplianceRequired: setTimeToDefault(value),
                                    ExternalDataItemId: row.original.ExternalDataItemId,
                                })}
                            }
                            className="singleAction"
                        />
                    ) : null}
                </div>
            )}
        </>
    )
}

export default withConfig(DateEditCell)
