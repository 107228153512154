import React from 'react'
import { FaCaretDown, FaCaretRight } from 'react-icons/fa'
import { DefaultColumnFilter } from '../components/elem/Grid/GridSearch'
import {
    Cell,
    NoteCell,
    ActionCell,
    InfoCell,
} from '../components/elem/Grid/Cells'

const getInputSortType = (dataType) => {
    switch (dataType) {
        case "Date":
            return 'date'
        case "Number":
            return 'alphanumeric'
        default :
            return 'caseinsensitive'
    }
}
const tableColumns = (process, order, processSubTypeColumn, cachedLayout) => {
    const cachedChecks = cachedLayout.checked 
    const variableColumns = Object.keys(process)
        .map((col) => {
            const columnFilter =
                process[col].DataType === 'Number' &&
                process[col].Label !== 'Lapse Time'
                    ? {
                          Filter: DefaultColumnFilter,
                          filter: 'equals',
                          width: 110,
                      }
                    : process[col].DataType === 'String' &&
                      process[col].Label !== 'Communication'
                    ? {
                          Filter: DefaultColumnFilter,
                          filter: 'text',
                          width: 120,
                      }
                    : {
                          canFilter: false,
                          disableFilters: true,
                          width: 110,
                      }
            return {
                ...columnFilter,
                Header: process[col].Label,
                accessor: col,
                defaultHidden: process[col].Hidden || (cachedChecks && cachedChecks.includes(`${process[col].Label}`)) ? true : false,
                sortType: getInputSortType(process[col].DataType),
                Cell: ({ cell, column, row }) => {
                    return (
                    <Cell
                        value={cell.value}
                        type={process[col].DataType}
                        column={column.id}
                        columnObject={column}
                        row={row}
                    />
                )},
            }
        })
        .map((item) => {
            if (item.accessor === 'LastAction') {
                item.accessor = 'StartDate'
            }
            if (item.accessor === 'RecordId') {
                item.accessor = 'ExternalDataItemId'
                item.width = 90
            }
            if (item.accessor === 'Status') {
                item.accessor = 'StateName'
            }
            if (item.accessor === 'LastNoteDate') {
                item.width = 130
            }
            if (item.accessor === 'NoteSummary') {
                item.width = 160
            }
            if (item.accessor === 'ActionGroup') {
                item.width = 90
            }
            return item
        })

    const fixedColumns = [
        {
            Header: '',
            accessor: 'ProcessId',
            disableFilters: true,
            width: 30,
            filterable: false,
            disableResizing: true,
            sortable: false,
            id: 'ProcessId',
            Cell: ({ row }) => (
                <NoteCell id={row.original.ExternalDataItemId} />
            ),
        },
        {
            Header: () => null,
            id: 'expander',
            accessor: 'expander',
            filterable: false,
            disableFilters: true,
            disableResizing: true,
            sortable: false,
            width: 30,
            Cell: ({ row }) => {
                return (
                    <span
                        {...row.getToggleRowExpandedProps()}
                        style={{
                            color: 'inherit',
                            verticalAlign: '-webkit-baseline-middle',
                            paddingTop: '1px',
                        }}
                    >
                        {row.isExpanded ? <FaCaretDown /> : <FaCaretRight />}
                    </span>
                )
            },
            SubCell: () => null,
        },
        {
            Header: '',
            id: 'attributeJSON', 
            accessor: 'AttributeJSON',
            disableFilters: true,
            width: 30,
            filterable: false,
            disableSortBy: true,
            disableResizing: true,
            sortable: false,
            Cell: ({ row }) => (
                <InfoCell
                    attributes={row.original.AttributeJSON}
                    id={row.original.ExternalDataItemId}
                />
            ),
        },
        {
            Header: 'Actions',
            accessor: 'StateId',
            disableFilters: true,
            filterable: false,
            sortable: false,
            width: 200,
            Cell: ({ row }) => (
                <ActionCell
                    processSubTypeColumn={processSubTypeColumn}
                    row={row}
                />
            ),
        }
    ]

    const columns = [...fixedColumns, ...variableColumns]
    const sortColumns = (columnArray, orderArray) =>
        orderArray.length
            ? columnArray.sort(
                  (a, b) =>
                      orderArray.indexOf(a.accessor) -
                      orderArray.indexOf(b.accessor)
              )
            : fixedColumns
                  .slice(0, 2)
                  .concat(variableColumns, fixedColumns.slice(2))

    const sortedColumns = sortColumns(columns, order)
    return sortedColumns
}

export default tableColumns
