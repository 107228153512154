import React, { useContext, useState, useEffect } from 'react'
import withConfig from '../../../wrappers/withConfig'
import toast from '../../../elem/Toast'
import { CurrentProcessContext } from '../../../wrappers/CurrentProcessContext'
import LoadingSpinner from '../../../elem/LoadingSpinner'
import useModal from '../../../hooks/useModal'
import { ConfirmModal } from '../../../elem/Modal'
import { DateSelector } from '../../../elem/Selectors'
import {
    dateToString,
    saveDate,
    daysUntilDate,
    dateAfterDays,
    setTimeToDefault,
} from '../../../../utils/formatDate'
import { FaEdit } from 'react-icons/fa'
import { APIRequestContext } from '../../../wrappers/APIRequestWithToken'
import { UserContext } from '../../../wrappers/UserContext'

const ActionSelect = ({ options, maxDuration, dataId, config }) => {
    const { isShowing, toggle } = useModal()
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { setAction, transitions, lastActions, setEditDate } = useContext(
        CurrentProcessContext
    )
    const { user } = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const { API_URL } = config

    const [transition, setTransition] = useState(null)
    const [option, setOption] = useState(null)
    const [editDur, setEditDur] = useState(false)
    const [calendarOpen, setCalendarOpen] = useState(false)
    const [date, setDate] = useState(null)
    const [duration, setDuration] = useState(null)
    const [originalDate, setOriginalDate] = useState(null)

    const [complianceAchievedDate, setComplianceAchievedDate] = useState(null)

    const lastAction = lastActions.filter(
        (action) => action.ExternalDataItemId === dataId
    )[0]

    useEffect(() => {
        if (isShowing) {
            const next = transitions.filter(
                (x) => x.CurrentStateId === option.NextStateId
            )
            const nextDueDate = next.map((y) =>
                dateAfterDays(y.StateMaxDurationDays)
            )[0]
            if (nextDueDate && next.length) {
                setOriginalDate(nextDueDate)
                setDate(nextDueDate)
                setDuration(next[0].StateMaxDurationDays)
            } else {
                setDate(null)
            }
        }
    }, [isShowing, transitions, option])

    if (maxDuration === -1 || !options) {
        return null
    }

    const handleChange = (e) => {
        setTransition(Number(e.target.value))
        const selectedOption = options.filter(
            (item) => item.ProcessStateTransitionId === Number(e.target.value)
        )[0]
        if (selectedOption) {
            setOption(selectedOption)
        }
    }

    const POST = (action) => {
        const formData = new FormData()
        formData.append('ProcessId', action.ProcessId)
        formData.append('ExternalDataItemId', action.ExternalDataItemId)
        formData.append('TransitionId', action.TransitionId)
        formData.append('ModifyUser', action.ModifyUser)
        return {
            method: 'POST',
            mode: 'cors',
            headers: {
                // 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: formData,
        }
    }
    const executeTransition = () => {
        if (!dataId || !transition) {
            console.log(dataId, transition, 'error')
        }

        setLoading(true) // set the loading to true
        authenticatedFetch(
            `${API_URL}/Process/executeTransition`,
            POST({
                ProcessId: options[0].ProcessId,
                ExternalDataItemId: dataId,
                TransitionId: transition,
                ModifyUser: user && user.profile && user.profile.name
            })
        )
            .then(async (response) => {
                if (response.ok) {
                    return response
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(() => {
                if (date && dateToString(originalDate) !== dateToString(date)) {
                    setEditDate({
                        StartDate: setTimeToDefault(saveDate()),
                        DueDate: setTimeToDefault(date),
                        ExternalDataItemId: [dataId],
                        ComplianceAchievedDate: null
                    })
                }
                else if (complianceAchievedDate) {
                    setEditDate({
                        StartDate: setTimeToDefault(saveDate()),
                        DueDate: null,
                        ExternalDataItemId: [dataId],
                        ComplianceAchievedDate: setTimeToDefault(complianceAchievedDate)
                    })                
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Workflow Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(() => {
                toggle()
                setAction(`${transition}_${dataId}`)
                setOption({})
                setTransition(false)
                setTimeout(() => setLoading(false), 1500)
            })
    }

    const cancel = () => {
        toggle()
    }

    const handleDateChange = (e) => {
        setDate(e)
        setDuration(daysUntilDate(e))
    }

    const handleDurationChange = (e) => {
        setDuration(Number(e.target.value))
        setDate(dateAfterDays(Number(e.target.value)))
    }

    const confirmation =
        option && Object.keys(option).length && isShowing ? (
            <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                Are you sure you want to move this item to{' '}
                {option.NextState} from {options[0].CurrentState}?{' '}
                {date ? (
                    <p className="actionDate">
                        The next action will be due in
                        {editDur ? (
                            <input
                                className="duration input"
                                type="number"
                                classname="input"
                                defaultValue={duration}
                                onChange={handleDurationChange}
                                onBlur={() => setEditDur(false)}
                            />
                        ) : (
                            <span
                                className="duration"
                                onClick={() => setEditDur(true)}
                            >
                                {' '}
                                {duration}
                                <FaEdit style={{ width: '.9em' }} />
                            </span>
                        )}{' '}
                        days on
                        {
                            <span>
                                <DateSelector
                                    onChange={handleDateChange}
                                    onFocus={() => setCalendarOpen(true)}
                                    onBlur={() => setCalendarOpen(false)}
                                    selected={date}
                                />
                                <FaEdit
                                    style={{
                                        width: '1em',
                                        display: `${
                                            calendarOpen ? 'none' : 'initial'
                                        }`,
                                    }}
                                />
                            </span>
                        }
                    </p>
                ) : (     
                    <span className="actionDate">
                        <p>
                            Please ensure associated records (e.g., Well, Facility) are up-to-date in WPF 
                            and that the Compliance Achieved Date is set in the date picker below before moving this record to an END status.
                        </p>
                        <DateSelector
                            onChange={e => setComplianceAchievedDate(e)}
                            onFocus={() => setCalendarOpen(true)}
                            onBlur={() => setCalendarOpen(false)}
                            selected={complianceAchievedDate}
                        />
                        <FaEdit
                            style={{
                                width: '1em',
                                display: `${
                                    calendarOpen ? 'none' : 'initial'
                                }`,
                            }}
                        />
                    </span>
                )}
            </div>
        ) : null

    return (
        <>
            <div
                className="selectSection single"
                style={{ color: 'inherit !important' }}
            >
                {lastAction && lastAction.TransitionName ? (
                    <div className="lastAction">
                        Last: {lastAction.TransitionName}
                    </div>
                ) : null}
                <div className="selectAction">
                    <select
                        className="select is-small is-rounded"
                        style={{ width: '140px' }}
                        onChange={handleChange}
                    >
                        {
                            <option
                                value=""
                                selected={!transition}
                                disabled
                                hidden
                            >
                                Select Action
                            </option>
                        }
                        {options.map((option, i) => (
                            <option
                                key={`action_option_${i}`}
                                value={option.ProcessStateTransitionId}
                            >
                                {option.TransitionName}
                            </option>
                        ))}
                    </select>
                    <span>
                        {loading === true ? (
                            <LoadingSpinner size="1.5em" />
                        ) : (
                            <button
                                onClick={toggle}
                                className="button is-small"
                                disabled={!transition}
                            >
                                Go
                            </button>
                        )}
                    </span>
                    {isShowing ? (
                        <ConfirmModal
                            confirmation={confirmation}
                            cancel={cancel}
                            submit={executeTransition}
                            className="singleAction"
                        />
                    ) : null}
                </div>

                {/* <p className="lastAction">{lastAction && lastAction.TransitionName ? `Last: ${lastAction.TransitionName}` : null}</p> */}
            </div>
        </>
    )
}

export default withConfig(ActionSelect)
