const StatusColors = {
    Resolved: '#188C4A',
    Low: '#69a004',
    Med: '#d49c02',
    High: '#ec6d0c',
    Overdue: '#CC0A00',
}

const StatusBackgroundColors = {
    Resolved: 'rgba(27, 157, 83, 0.30)',
    Low: 'rgba(174, 209, 77, 0.30)',
    Med: 'rgba(254, 206, 72, 0.30)',
    High: 'rgba(252, 121, 22, 0.3)',
    Overdue: 'rgba(250, 33, 21, 0.3)',
}

const filterColors = [
    'rgba(244, 143, 177, 0.6)', // light pink
    'rgba(233, 30, 99, 0.5)', // pink 500
    '#CD6889',
    'rgba(136, 14, 79, 0.6)',
    'rgba(136, 14, 79, 0.8)',
    '#FFBBFF',
    'rgba(171, 71, 188, 0.9)', // purple 400
    '#7A378B',
    '#cab2d6', // lavender
    'rgba(120, 58, 183, .9)', // deep purple 500
    '#8470FF',
    '#CAE1FF', // light blue
    '#7EC0EE',
    'rgba(63, 81, 181, .95)', // indigo 500
    'rgba(31, 120, 180, 0.9)', // blue
    'rgba(0, 188, 212, 0.7)', // cyan 500
    'rgba(0, 150, 136, 0.4)',
    'rgba(30, 130, 145, 0.9)',
    '#C1FFC1',
    'rgba(0, 150, 100, 0.6)',
    '#009688', // teal 500

    '#FFFFE0',
    '#FFF68F',
    '#FFB90F',
    '#F4A460',
    '#FF7F50',
    '#CD4F39',
    '#CD3333'
]

export { StatusColors, StatusBackgroundColors, filterColors }
