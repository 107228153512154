const filterByAttributes = (data, attributes) => {
    return data.filter((item) => {
        return Object.keys(attributes).every((filter) => {
            const attribute = item.AttributeJSON[filter]
            if (
                attribute &&
                attributes[filter].Type === 'String' &&
                !(
                    attribute
                        .toLowerCase()
                        .indexOf(attributes[filter].Value.toLowerCase()) === -1
                )
            )
                return true
            if (
                attribute &&
                attributes[filter].Type === 'Combo' &&
                attribute.toLowerCase() ===
                    attributes[filter].Value.toLowerCase()
            )
                return true
            if (attribute && attributes[filter].Type === 'Number')
                return (!attributes[filter].Max ||
                    Number(attributes[filter].Max) >= Number(attribute)) &&
                    (!attributes[filter].Min ||
                        Number(attributes[filter].Min) <= Number(attribute))
                    ? true
                    : false
            if (attribute && attributes[filter].Type === 'Date')
                return (!attributes[filter].MaxDate ||
                    attributes[filter].MaxDate >=
                        new Date(attribute.slice(0, -2))) &&
                    (!attributes[filter].MinDate ||
                        attributes[filter].MinDate <=
                            new Date(attribute.slice(0, -2)))
                    ? true
                    : false
            else {
                return false
            }
        })
    })
}

export default filterByAttributes
