import React, { useContext } from 'react'
import PageLayout from '../../elem/Layout/PageLayout'
import { ProcessContext } from '../../wrappers/ProcessContext'
import ProcessCard from './ProcessCard'

const LandingPage = () => {
    const { processes } = useContext(ProcessContext)

    if (!processes && !processes.length) {
        return null
    }

    return (
        <PageLayout title={`Workflow Management`}>
            <div className="section">
                <div className="section">
                    <div className="processLinks">
                        {processes.map((process, idx) => {
                            return (
                                <ProcessCard
                                    key={`process-${idx}`}
                                    process={process}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default LandingPage
