import React, { createContext, useState, useEffect, useContext } from 'react'
import withConfig from './withConfig'
import toast from '../elem/Toast'
import { APIRequestContext } from './APIRequestWithToken'

const ProcessContext = createContext(null)

const ProcessContextProvider = ({ config, children }) => {
    const { API_URL } = config
    const [processes, setProcesses] = useState([])
    const [loading, setLoading] = useState(true)
    const { authenticatedFetch } = useContext(APIRequestContext)

    useEffect(() => {
        setLoading(true)
        authenticatedFetch(`${API_URL}/Process/Processes`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setProcesses(response)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Workflow Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [API_URL])

    return (
        <ProcessContext.Provider
            value={{
                loading,
                processes,
            }}
        >
            {children}
        </ProcessContext.Provider>
    )
}

export { ProcessContext }
export default withConfig(ProcessContextProvider)
