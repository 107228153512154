import dayjs from 'dayjs'

const frontFormat = 'MM/DD/YYYY'
const backFormat = 'YYYY-MM-DDTHH:mm:ss'
const dateToString = (date) => dayjs(date).format(frontFormat).toString()
const saveDate = (date) => {
    //if date is not provided (undefined) will return current date
    return dayjs(date).format(backFormat)
}
const daysUntilDate = (date) =>
    Math.ceil(dayjs(date).diff(dayjs(), 'day', true))
const dateAfterDays = (days) => dayjs().add(days, 'day').$d

const setTimeToDefault = date => {
    return date //takes a dayjs object 
    ? dayjs(date)
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)
        .format(backFormat)
    : date  
}

export { dateToString, saveDate, daysUntilDate, dateAfterDays, setTimeToDefault }
