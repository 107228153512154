import React, { useContext, useMemo, useCallback } from 'react'
import GridLayout from 'react-grid-layout'
import { CurrentProcessContext } from '../../wrappers/CurrentProcessContext'
import { saveLayout, clearLayout } from '../../../utils/localStorage/layouts'

const ColumnSelectionModal = ({
    columns,
    setColumnOrder,
    toggleHiddenColumns,
    setCustomOrderFlag,
    processId,
    cachedLayout,
    setCachedLayout,
}) => {
    const { gridConfigExpanded } = useContext(CurrentProcessContext)
    const selectableColumns = useMemo(
        () =>
            columns.filter(
                (x) => typeof x.Header !== 'function' && x.Header !== '' && x.Header !== "Actions"
            ),
        [columns]
    )
    const layout = useMemo(() => {
        return cachedLayout && cachedLayout.layout
            ? cachedLayout.layout
            : selectableColumns.map((x, idx) => ({
                  i: x.Header,
                  x: 0,
                  y: idx,
                  w: 12,
                  h: 1,
              }))
    }, [selectableColumns, cachedLayout])

    const onLayoutChange = useCallback(
        (layout) => {
            const orderedLayout = layout.sort((a, b) => a.y - b.y)
            const orderedColumns = orderedLayout
                .map((item) => {
                    return selectableColumns.find(
                        (col) => col.Header === item.i
                    )
                })
                .map((x) => x.id)
            setCustomOrderFlag(true)
            setColumnOrder([
                'selection',
                'ProcessId',
                'expander',
                ...orderedColumns,
                'attributeJSON',
            ])
        },
        [selectableColumns, setColumnOrder, setCustomOrderFlag]
    )

    const checkedColumns = selectableColumns
        .filter((col) => col.getToggleHiddenProps().checked !== true)
        .map((col) => col.Header)

    const resetConfig = () => {
        clearLayout(processId)
        setCachedLayout({})
        setCustomOrderFlag(false)
    }

    const saveConfig = () => {
        saveLayout(processId, { layout: layout, checked: checkedColumns })
        setCachedLayout({ layout: layout, checked: checkedColumns })
    }

    return (
        <div
            className={`columnSelectionModalWrapper ${
                gridConfigExpanded ? '' : 'hidden'
            }`}
        >
            <div className="columnSelectionModal">
                <div className="is-size-5 ">
                    <button
                        className={'button is-small is-inverted'}
                        style={{ marginRight: '5px' }}
                        onClick={() => saveConfig()}
                    >
                        Save Configuration
                    </button>

                    <button
                        className={'button is-small is-inverted'}
                        style={{ marginBottom: '5px' }}
                        onClick={() => resetConfig()}
                    >
                        Restore Default
                    </button>

                    <hr />
                </div>
                <GridLayout
                    className="layout"
                    draggableHandle={'.columnSelectionLabelWrapper'}
                    layout={layout}
                    cols={12}
                    rowHeight={30}
                    width={200}
                    onLayoutChange={onLayoutChange}
                >
                    {selectableColumns.map((column) => {
                        const inputProps = {
                            ...column.getToggleHiddenProps(),
                            onChange: (target) => {
                                column.getToggleHiddenProps().onChange(target)
                                toggleHiddenColumns(column)
                            },
                        }
                        return (
                            <div
                                className="columnSelectionRow"
                                key={column.Header}
                            >
                                <div className="columnSelectionCheckboxWrapper">
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        {...inputProps}
                                    />
                                </div>
                                <div className="columnSelectionLabelWrapper">
                                    <span className="columnSelectionLabel is-size-7">
                                        {column.Header}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </GridLayout>
            </div>
        </div>
    )
}

export default ColumnSelectionModal
