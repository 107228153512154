import React, {
    createContext,
    useState,
    useMemo,
    useEffect,
    useContext,
} from 'react'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { ProcessContext } from './ProcessContext'
import withConfig from './withConfig'
import toast from '../elem/Toast'
import { APIRequestContext } from './APIRequestWithToken'

const ParameterContext = createContext(null)

const ParameterContextProvider = ({ config, children }) => {
    const { API_URL } = config
    const { processes } = useContext(ProcessContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { search } = useLocation()
    const location = useLocation()
    const history = useHistory()
    const queryParams = useMemo(() => queryString.parse(search), [search])
    const [query, setQuery] = useState({})
    const [clear, setClear] = useState(false)
    const [processId, setProcessId] = useState(null)
    const gridQuery = Object.keys(queryParams).map((x) => ({
        id: x,
        value: queryParams[x],
    }))

    useEffect(() => {
        if (processId) {
            const process = processes.filter(
                (process) => process.ProcessId === processId
            )[0]
            if (process) {
                history.push({ ...location, pathname: process.ProcessName })
            }
        }
    }, [processId])

    useEffect(() => {
        if (
            queryParams &&
            queryParams.ExternalDataItemId &&
            location.pathname === '/'
        ) {
            authenticatedFetch(
                `${API_URL}/Process/externaldatabyitemid/${queryParams.ExternalDataItemId}`
            )
                .then(async (response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then((response) => {
                    if (response.length) {
                        setProcessId(response[0].ProcessId)
                    }
                })
                .catch((e) => {
                    toast({
                        level: 'error',
                        message:
                            'Workflow Landing Page:' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server'),
                    })
                })
        }
    }, [query])

    useEffect(() => {
        if (query && Object.keys(query).length) {
            history.push({
                search: queryString.stringify({ ...queryParams, ...query }),
            })
        }
    }, [query])

    return (
        <ParameterContext.Provider
            value={{
                setQuery,
                queryParams,
                gridQuery,
                clear,
                setClear,
            }}
        >
            {children}
        </ParameterContext.Provider>
    )
}

export { ParameterContext }
export default withConfig(ParameterContextProvider)
