import React, { useState, useContext } from 'react'
import {
    DateRangeSelector,
    NumberRangeSelector,
    StringInput,
    SelectFilter,
} from '../../../elem/Selectors'
import { FaCaretDown, FaCaretRight } from 'react-icons/fa'
import useKeyPress from '../../../hooks/useKeyPress'
import { FilterContext } from '../../../wrappers/FilterContext'
import { CurrentProcessContext } from '../../../wrappers/CurrentProcessContext'
import calcAttFilters from '../../../../utils/calcAttFilters'
import { FaCircle } from 'react-icons/fa'

const CriteriaFilter = ({ filters }) => {
    const [show, setShow] = useState(false)
    // const [display, setDisplay] = useState([])
    const { criteria, setCriteria, selectOptions, temp, setTemp } = useContext(FilterContext)
    const { processSubTypeColumn } = useContext(CurrentProcessContext)

    const handleClick = () => {
        setCriteria({})
        if (Object.keys(temp).length) {
            setCriteria(calcAttFilters(temp))
        }
    }

    const handleClear = (e) => {
        setTemp({})
        setCriteria({})
        // setDisplay([])
    }

    useKeyPress(temp, 'Enter', () => {
        handleClick()
    })

    const applied = Object.keys(filters)
        .filter(
            (key) =>
                Object.keys(criteria).length &&
                Object.keys(criteria).includes(key)
        )
        .map((item) => filters[item].Label)

    return (
        <div className="criteriaFilter">
            <div>
                <div className="headerBar">
                    <h6
                        className="title is-size-7"
                        onClick={() => setShow(!show)}
                    >
                        Additional Filters{' '}
                        {show ? <FaCaretDown /> : <FaCaretRight />}
                    </h6>
                    <h6
                        className={
                            applied.length && !show ? 'applied' : 'hidden'
                        }
                    >
                        <span className="is-size-7">{`Applied: `}</span>

                        {applied.length
                            ? applied.map((item) => (
                                  <span className="is-size-7">{item}</span>
                              ))
                            : null}
                    </h6>
                </div>
                <button
                    className={show ? 'button is-small is-inverted' : 'hidden'}
                    style={{ marginLeft: '1%' }}
                    onClick={handleClick}
                >
                    Apply
                </button>
                <button
                    className={show ? 'button is-small is-inverted' : 'hidden'}
                    onClick={handleClear}
                >
                    Remove
                </button>
            </div>
            <div
                className={show ? 'box' : 'hidden'}
                style={{
                    width: 'fit-content',
                    padding: '.15rem',
                    marginTop: '0px',
                }}
            >
                {Object.keys(filters).filter(x => x !== processSubTypeColumn).map((item, i) => (
                    <div className="attributeFilterItem" key={`${item}_${i}`}>
                        <h6
                            className="is-size-7"
                            style={
                                criteria[item] ? { fontStyle: 'italic' } : null
                            }
                        >
                            {criteria[item] ? (
                                <span
                                    style={{
                                        maxHeight: '.65rem',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <FaCircle />
                                </span>
                            ) : null}
                            {filters[item].Label}:{' '}
                        </h6>
                        {filters[item].DataType === 'Date' ? (
                            <>
                                <DateRangeSelector
                                    setCriteria={setTemp}
                                    criteria={criteria}
                                    label={item}
                                />
                                {/* {criteria[item]
                                    ? <p>{
                                          criteria[item].MinDate
                                              ? `Min: ${dateToString(criteria[item].MinDate)}`
                                              : ''
                                      } ${
                                          criteria[item.MaxDate]
                                              ? `Max: ${dateToString(criteria[item].MaxDate)}`
                                              : ''
                                      }</p>
                                    : null} */}
                            </>
                        ) : filters[item].DataType === 'String' ? (
                            filters[item].ControlType === 'Combo' ? (
                                <>
                                    <SelectFilter
                                        temp={temp}
                                        setTemp={setTemp}
                                        item={item}
                                        options={
                                            selectOptions[item]
                                                ? selectOptions[item]
                                                : []
                                        }
                                    />
                                    {/* {criteria[item]
                                    ? <p>{criteria[item].Value}</p>
                                    : null} */}
                                </>
                            ) : (
                                <>
                                    <StringInput
                                        temp={temp}
                                        setTemp={setTemp}
                                        item={item}
                                    />
                                    {/* {criteria[item]
                                    ? <p>{criteria[item].Value}</p>
                                    : null} */}
                                </>
                            )
                        ) : (
                            <>
                                <NumberRangeSelector
                                    temp={temp}
                                    setTemp={setTemp}
                                    item={item}
                                />
                                {/* {criteria[item]
                                    ? <p>{
                                          criteria[item].Min
                                              ? `Min: ${criteria[item].Min}`
                                              : ''
                                      } ${
                                          criteria[item.Max]
                                              ? `Max: ${criteria[item].Max}`
                                              : ''
                                      }</p>
                                    : null} */}
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CriteriaFilter
