import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import NotesModal from '../Notes/NotesModal'
import dayjs from 'dayjs'
import UndoAction from '../Actions/UndoAction'

const format = 'MM/DD/YYYY'
const dateToString = (date) =>
    date ? dayjs(date).format(format).toString() : null

const SubGrid = ({ externalItemDates, id, updateGrid }) => {
    const DateCell = ({ value, cell, rows }) => {
        if (!value || !cell) {
            return null
        }
        const undoCell = (
            <div className="is-flex is-align-items-center">
                {dateToString(value)}
                <UndoAction
                    lastRow={rows.slice(-1)[0]}
                    updateGrid={updateGrid}
                />
            </div>
        )

        return Number(cell.row.id) === rows.length - 1 &&
            cell.column.id === 'StartDate' &&
            rows.length > 1
            ? undoCell
            : dateToString(value)
    }
    const NoteCell = ({ id, stateId }) => (
        <NotesModal id={id} stateId={stateId} />
    )

    const Cell = ({ value }) => {
        return value ? value : null
    }

    const createColumns = () => [
        {
            Header: 'Starting State',
            accessor: 'StartStateName',
        },
        {
            Header: 'Start Date',
            accessor: 'StartDate',
            Cell: ({ cell, rows }) => (
                <DateCell value={cell.value} rows={rows} cell={cell} />
            ),
        },
        {
            Header: 'Ending State',
            accessor: 'EndStateName',
            Cell: ({ cell }) => <Cell value={cell.value} />,
        },
        {
            Header: 'End Date',
            accessor: 'EndDate',
            Cell: ({ cell }) => (
                <DateCell style={{ paddingRight: '3rem' }} value={cell.value} />
            ),
        }
        // ,
        // {
        //     Header: '',
        //     accessor: 'ExternalDataItemProcessStateId',
        //     id: 'note',
        //     Cell: ({ row }) => (
        //         <NoteCell
        //             id={row.original.ExternalDataItemId}
        //             stateId={row.original.ExternalDataItemProcessStateId}
        //         />
        //     ),
        // },
    ]

    const columns = useMemo(() => createColumns(), [])
    const tableData = useMemo(() => externalItemDates, [externalItemDates])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data: tableData,
    })

    return (
        <div>
            <table
                {...getTableProps()}
                className="table is-fullwidth is-hoverable"
                style={{ backgroundColor: 'inherit' }}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        const rowProps = row.getRowProps()
                        return (
                            <tr {...rowProps}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            style={{ position: 'relative' }}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default SubGrid
