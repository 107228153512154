import React from 'react'
import { FaSyncAlt } from 'react-icons/fa'
import ExportButton from '../../../elem/ExportButton'
import DefaultTooltip from '../../../elem/Tooltip'
import GridConfig from '../../../elem/Grid/GridConfig'

const GridControls = ({ refreshData, filtRows, filteredData, lastUpdate }) => {
    return (
        <div className="controls">
            <div className="controlButtonWrapper">
                <div
                    data-tip={`Refresh Data <br/> <hp className="is-size-7">Last Updated: ${lastUpdate}</hp>`}
                    data-for="refresh"
                    className=" is-size-65"
                    onClick={refreshData}
                >
                    <FaSyncAlt />
                    <DefaultTooltip id="refresh" place={'top'} />
                </div>
            </div>
            <GridConfig />
            <ExportButton
                data={filtRows ? filtRows : filteredData}
                view={'Filtered'}
            />
            <ExportButton data={'All'} view={'All'} />
        </div>
    )
}

export default GridControls
