import React, { useState, useContext, useEffect } from 'react'
import { NoteContext } from '../../../wrappers/NoteContext'
import { useForm, Controller } from 'react-hook-form'
import { DateSelector } from '../../../elem/Selectors'

import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'

import { Editor } from 'react-draft-wysiwyg'
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import dayjs from 'dayjs'

// var utc = require('dayjs/plugin/utc')
// dayjs.extend(utc)
const dateFormat = 'MM/DD/YYYY'
const dateToString = (date) => (date ? dayjs(date).format() : dayjs().format())
const stringToDate = (date) => (date ? dayjs(date).toDate() : null)

const CommentEditor = ({ editNote, setEditNote }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const { noteTypes, setComment } = useContext(NoteContext)
    const [visible, setVisible] = useState(false)
    const [noteError, setNoteError] = useState(false)
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        if (editNote && editNote.NoteBody) {
            const blocksFromHTML = convertFromHTML(editNote.NoteBody)
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )
            setEditorState(EditorState.createWithContent(state))
            setValue('NoteSubject', editNote.NoteSubject)
            setValue('NoteType', editNote.NoteType)
            setValue('NoteDate', stringToDate(editNote.NoteDate))
            setVisible(true)
        } else {
            setValue('NoteDate', null)
        }
    }, [editNote])

    const MarkdownEditor = () => {
        const onEditorStateChange = (editorState) => {
            setEditorState(editorState)
            if (noteError) {
                setNoteError(false)
            }
        }
        return (
            <Editor
                toolbar={{
                    options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'fontFamily',
                        'list',
                        'textAlign',
                        'colorPicker',
                        'history',
                    ],
                    inline: {
                        options: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                        ],
                    },
                    textAlign: {
                        inDropdown: true,
                        expanded: true,
                    },
                }}
                editorState={editorState}
                wrapperClassName={
                    visible ? 'editorWrapper focus' : 'editorWrapper'
                }
                editorClassName="editor"
                toolbarClassName="toolbar"
                placeholder="    ...add a note"
                toolbarStyle={!visible ? { display: 'none' } : null}
                onEditorStateChange={onEditorStateChange}
            />
        )
    }

    const handleCancel = () => {
        setEditorState(EditorState.createEmpty())
        setVisible(false)
        setValue('NoteSubject', null)
        setValue('NoteType', 'GENERAL')
        setEditNote(null)
    }

    const onSubmit = (data) => {
        const note = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if (note && note !== '') {
            setComment({
                Type: 'New',
                Note: {
                    ...data,
                    NoteBody: note,
                    NoteDate: dateToString(data.NoteDate),
                    ModifyDate: dateToString(),
                },
            })
            setEditorState(EditorState.createEmpty())
            setVisible(false)
            setValue('NoteSubject', null)
        }
        setNoteError(true)
    }

    const onEdit = (data) => {
        const note = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        if (editNote) {
            if (note && note !== '') {
                setComment({
                    Type: 'Edit',
                    Note: {
                        ...editNote,
                        ...data,
                        NoteBody: note,
                        ModifyDate: dateToString(),
                        NoteDate: dateToString(data.NoteDate),
                    },
                })
                setEditorState(EditorState.createEmpty())
                setVisible(false)
                setEditNote(false)
                setValue('NoteSubject', null)
            }
            setNoteError(true)
        }
    }
    return (
        <div
            style={{
                width: '100%',
                height: 'fit-content',
                marginBottom: '1.5%',
            }}
        >
            <div onFocus={() => setVisible(true)}>
                <form className="form">
                    <div style={!visible ? { display: 'none' } : null}>
                        <div className="is-flex">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <input
                                    className="input"
                                    type="text"
                                    name="NoteSubject"
                                    placeholder="Note Subject"
                                    defaultValue={
                                        editNote ? editNote.NoteSubject : null
                                    }
                                    {...register('NoteSubject', {
                                        required: {
                                            value: true,
                                            message: 'This field is required.',
                                        },
                                        maxLength: {
                                            value: 50,
                                            message:
                                                'Subject limit 50 characters.',
                                        },
                                    })}
                                />
                                {errors && errors.NoteSubject
                                    ? errors.NoteSubject.message
                                    : null}
                            </div>

                            <Controller
                                name="NoteDate"
                                control={control}
                                defaultValue={
                                    editNote
                                        ? stringToDate(editNote.NoteDate)
                                        : null
                                }
                                render={({
                                    field: { onChange, value, ref },
                                }) => (
                                    <DateSelector
                                        onChange={onChange}
                                        selected={value}
                                        placeholder={dayjs().format(dateFormat)}
                                    />
                                )}
                            />
                        </div>
                        <div class="control">
                            {noteTypes.map((type) => (
                                <label className="radio is-size-7">
                                    <input
                                        {...register('NoteType', {
                                            required: true,
                                        })}
                                        type="radio"
                                        value={type.Code}
                                        defaultChecked={
                                            editNote &&
                                            editNote.NoteType === type.Code
                                                ? true
                                                : !editNote &&
                                                  type.Code === 'GENERAL'
                                                ? true
                                                : false
                                        }
                                    />
                                    {type.Description}
                                </label>
                            ))}
                        </div>
                    </div>
                </form>
                <MarkdownEditor />

                {noteError ? (
                    <span style={!visible ? { display: 'none' } : null}>
                        This field is required.
                    </span>
                ) : null}
                <div className="buttons">
                    <button
                        className="button is-small is-blue"
                        style={!visible ? { display: 'none' } : null}
                        onClick={
                            editNote
                                ? () => handleSubmit(onEdit)()
                                : () => handleSubmit(onSubmit)()
                        }
                    >
                        Save {editNote ? 'Edit' : 'New'}
                    </button>
                    <button
                        className="button is-small is-blue"
                        type="button"
                        style={!visible ? { display: 'none' } : null}
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CommentEditor
