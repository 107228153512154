import React from 'react'
import NavBar from '../../features/navigation/Navbar'

const PageLayout = ({children, title }) => {
    return (
        <div className="pageWrapper">
            <NavBar title={title}/>
            {children}
        </div>
    )
}

export default PageLayout