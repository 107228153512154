import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import UserContext from './components/wrappers/UserContext'
import ProcessContext from './components/wrappers/ProcessContext'
import ParameterContext from './components/wrappers/ParameterContext'
import NoteContext from './components/wrappers/NoteContext'
import CurrentProcessContext from './components/wrappers/CurrentProcessContext'
import Login from './components/features/landing/Login'
import ProcessSelection from './components/features/landing/ProcessSelection'
import ProcessPage from './components/features/processPage/ProcessPage'
import Header from './components/elem/Header'
import { Toast as ToastContainer } from './components/elem/Toast'
import APIRequestContextProvider from './components/wrappers/APIRequestWithToken'
import FilterContextProvider from './components/wrappers/FilterContext'
import UserContextProvider from './components/wrappers/UserContext'
import RestrictedAccess from './components/features/user/RestrictedAccess'
import urls from './utils/constants/urls'
import CallbackComponent from './components/features/user/CallbackComponent'

const App = () => (
    <React.Fragment>
        <Header />
        <div className="pageContent">
            <Router>
                <Switch>
                    <UserContextProvider>
                        <APIRequestContextProvider>
                            {/* <UserContext> */}
                            <ProcessContext>
                                <ParameterContext>
                                    <Route exact path={urls.userCallback}>
                                        <CallbackComponent />
                                    </Route>
                                    <Route exact path="/">
                                        <RestrictedAccess
                                            allowedRoles={[
                                                'Administrator',
                                                'UTWorkflow_UTWorkflow',
                                            ]}
                                        >
                                            <ProcessSelection />
                                        </RestrictedAccess>
                                    </Route>
                                    <Route exact path="/:processName">
                                        <RestrictedAccess
                                            allowedRoles={[
                                                'Administrator',
                                                'UTWorkflow_UTWorkflow',
                                            ]}
                                        >
                                            <CurrentProcessContext>
                                                <NoteContext>
                                                    <FilterContextProvider>
                                                        <ProcessPage />
                                                    </FilterContextProvider>
                                                </NoteContext>
                                            </CurrentProcessContext>
                                        </RestrictedAccess>
                                    </Route>
                                </ParameterContext>
                            </ProcessContext>
                            <Route path="/login" component={Login} />
                            {/* </UserContext> */}
                        </APIRequestContextProvider>
                    </UserContextProvider>
                </Switch>
            </Router>
        </div>
        <ToastContainer />
    </React.Fragment>
)

export default App
