import React, { useContext, useState, useMemo, useEffect } from 'react'
import Modal, { ConfirmModal, InformModal } from '../../../elem/Modal'
import useModal from '../../../hooks/useModal'
import { FaCommentAlt } from 'react-icons/fa'
import CommentEditor from './CommentEditor'
import { NoteContext } from '../../../wrappers/NoteContext'
import withConfig from '../../../wrappers/withConfig'
import toast from '../../../elem/Toast'
import { APIRequestContext } from '../../../wrappers/APIRequestWithToken'
import { FaTrash, FaEdit } from 'react-icons/fa'
import LoadingSpinner from '../../../elem/LoadingSpinner'
import { dateToString } from '../../../../utils/formatDate'

const NotesModal = ({ config, id, stateId }) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { isShowing, toggle } = useModal()
    const { setItemId, notes, loading, setReload } = useContext(NoteContext)
    const [confirm, setConfirm] = useState(false)
    const [inform, setInform] = useState(false)
    const [popUp, setPopUp] = useState(false)
    const [editNote, setEditNote] = useState(null)
    const noteList = useMemo(() => notes, [notes])

    const type = stateId === undefined ? 'item' : 'state'

    useEffect(() => {
        if (!isShowing && editNote) {
            setEditNote(false)
        }
    }, [isShowing])

    const handleOpen = () => {
        setItemId({
            ExternalDataItemId: id,
            Type: type,
            ExternalDataItemProcessStateId:
                stateId === 'undefined' ? null : stateId,
        })
        setPopUp(false)
        setTimeout(() => toggle(), 200)
    }

    const handleMouseEnter = () => {
        if (!isShowing) {
            setItemId({
                ExternalDataItemId: id,
                Type: type,
                ExternalDataItemProcessStateId:
                    stateId === 'undefined' ? null : stateId,
            })
            setPopUp(true)
        }
    }

    const handleMouseLeave = () => {
        setPopUp(false)
    }

    const handleDelete = (id) => {
        authenticatedFetch(`${API_URL}/process/deleteNote/${id}`, {
            method: 'DELETE',
        })
            .then(async (response) => {
                if (response.ok) {
                    return
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        `Failed to delete note:` +
                        (e.message ? e.message : null),
                })
            })
            .finally(() => {
                setReload(true)
                setConfirm(false)
            })
    }

    return isShowing ? (
        <Modal
            isShowing={isShowing}
            toggle={toggle}
            className="comments"
            header={
                <h5>
                    View/Add Notes{' '}
                    {type === 'item' ? `for Record Id ${id}` : null}{' '}
                </h5>
            }
            footer={
                <CommentEditor editNote={editNote} setEditNote={setEditNote} />
            }
            body={
                noteList.length ? (
                    <ul>
                        {noteList.map((note) => (
                            <li className="notes">
                                <div className="is-flex is-justify-content-space-between is-align-items-center">
                                    <div className="is-flex is-align-items-center">
                                        <h5 className="is-size-6">
                                            {note.ModifyUser}:{' '}
                                        </h5>
                                        <h5 className="is-size-65">
                                            {note.NoteSubject}
                                        </h5>
                                    </div>
                                    {confirm ? (
                                        <ConfirmModal
                                            confirmation={`Delete note ${confirm.NoteSubject}?`}
                                            cancel={() => setConfirm(false)}
                                            submit={() =>
                                                handleDelete(confirm.NoteId)
                                            }
                                            className="deleteNote"
                                        />
                                    ) : null}
                                    {inform ? (
                                        <InformModal
                                            information={`You may not delete or edit Source Comments.`}
                                            cancel={() => setInform(false)}
                                            className="deleteNote"
                                        />
                                    ) : null}
                                    <div
                                        className="is-flex is-align-items-center"
                                        style={{ marginRight: '2%' }}
                                    >
                                        <h7 className="is-size-7">
                                            {new Date(
                                                note.NoteDate
                                            ).toDateString()}
                                        </h7>
                                        <FaEdit
                                            onClick={() =>
                                                note.ModifyUser != 'System'
                                                    ? setEditNote(note)
                                                    : setInform(true)
                                            }
                                        />
                                        <FaTrash
                                            onClick={() =>
                                                note.ModifyUser != 'System'
                                                    ? setConfirm(note)
                                                    : setInform(true)
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className="commentBody"
                                    dangerouslySetInnerHTML={{
                                        __html: note.NoteBody,
                                    }}
                                ></div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="is-size-6">No saved notes</p>
                )
            }
        />
    ) : (
        <>
            <div
                className="modalButton"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleOpen}
            >
                <div className={!popUp ? 'hidden' : 'connect'}></div>
                <div
                    style={
                        !noteList.length && popUp
                            ? {
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '155px',
                                  paddingBottom: '1%',
                              }
                            : null
                    }
                    className={
                        !popUp
                            ? 'hidden'
                            : 'popUp notesPopup box is-flex is-align-items-center'
                    }
                >
                    {loading ? (
                        <div style={{ margin: '.25rem', textAlign: 'center' }}>
                            <LoadingSpinner />
                        </div>
                    ) : !noteList.length ? (
                        <p
                            className="is-size-7"
                            style={{ marginBottom: '3%', textAlign: 'center' }}
                        >
                            No saved notes
                        </p>
                    ) : (
                        <ul style={{ maxWidth: '40vw' }}>
                            {[...noteList].slice(0, 3).map((note) => (
                                <li>
                                    <div className="is-flex is-align-items-center is-justify-content-space-between">
                                        <label
                                            className="is-size-7"
                                            style={{
                                                marginRight: '3%',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {dateToString(
                                                new Date(note.NoteDate)
                                            )}
                                        </label>
                                        <h5
                                            className=""
                                            style={{
                                                marginRight: '3%',
                                                fontWeight: 'bold',
                                                fontSize: 'small',
                                            }}
                                        >
                                            {note.NoteSubject}
                                        </h5>
                                        <h7 className="is-size-7">
                                            {note.NoteType}
                                        </h7>
                                    </div>
                                    <div
                                        className="commentBody has-text-7 is-size-7"
                                        dangerouslySetInnerHTML={{
                                            __html: note.NoteBody,
                                        }}
                                    ></div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <FaCommentAlt />
            </div>
        </>
    )
}
export default withConfig(NotesModal)
