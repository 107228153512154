import React, { useContext } from 'react'
import { CurrentProcessContext } from '../../wrappers/CurrentProcessContext'
import ProcessGrid from './Grid/ProcessGrid'
import BarFilters from './Filters/BarFilters'
import CriteriaFilter from './Filters/CriteriaFilter'
import { FilterContext } from '../../wrappers/FilterContext'
import columnOrder from '../../../utils/columnOrders'
import PageLayout from '../../elem/Layout/PageLayout'

const ProcessPage = () => {
    const {
        process,
        externalDataItems,
        transitions,
        action,
        setAction,
        loading,
        processSubTypeColumn
    } = useContext(CurrentProcessContext)
    const { filters, filteredData, setFilteredData, processConfig } = useContext(FilterContext)

    if (!process) {
        return null
    }

    const groups = [...new Set(transitions.map((item) => item.ActionGroup))]

    const gridColumnOrder = columnOrder[process.ProcessId] 
    
    return (
        <PageLayout title={`${process.ProcessName} Management`}>
            <div className="smallSection">
                <div className="filters smallSubSection">
                    <BarFilters
                        externalDataItems={
                            externalDataItems.length ? externalDataItems : []
                        }
                        groups={groups ? groups : []}
                    />

                    <CriteriaFilter
                        externalDataItems={externalDataItems}
                        filters={processConfig.filterColumns}
                    />
                </div>
                <div className="grid smallSubSection">
                    <ProcessGrid
                        attributeColumns={processConfig.gridColumns}
                        setFilteredData={setFilteredData}
                        actioned={action}
                        setActioned={setAction}
                        filteredData={filteredData ? filteredData : []}
                        transitions={transitions ? transitions : []}
                        filters={filters}
                        processName={process.ProcessName}
                        loading={loading}
                        order={gridColumnOrder ? gridColumnOrder : []}
                        processSubTypeColumn={processSubTypeColumn}
                        processId={process.ProcessId}
                    />
                </div>
            </div>
        </PageLayout>
    )
}

export default ProcessPage
