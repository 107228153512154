const columnOrder = {
    //default order process by processId
    1: [
        'ProcessId',
        'expander',
        'FacilityName',
        'DateViolation',
        'ComplianceType',
        'EnforcementType',
        'OperatorName',
        'API14',
        'WellName',
        'Inspector',
        'LastProductionPeriod',
        'MonthsShutIn',
        'ActionGroup',
        'WorkflowStartDate',
        'DueDate',
        "DateComplianceRequired",
        "ComplianceAchievedDate",
        'StartDate',
        'LastNoteDate',
        'StateName',
        'NoteSummary',
        'StateId',
        'ExternalDataItemId',
        'AttributeJSON',
    ],
}
export default columnOrder
