import React from 'react'
import { Link } from 'react-router-dom'

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ process }) => {
    return (
        <Link to={`/${process.ProcessName}`}>
            <div className="box">
                <div className="card-header">
                    <h3 className="card-header-title is-size-4 is-centered">
                        {process.ProcessName}
                    </h3>
                </div>
            </div>
        </Link>
    )
}
