import React, { useContext, useState } from 'react'
import withConfig from '../../../wrappers/withConfig'
import { APIRequestContext } from '../../../wrappers/APIRequestWithToken'
import toast from '../../../elem/Toast'
import { CurrentProcessContext } from '../../../wrappers/CurrentProcessContext'
import LoadingSpinner from '../../../elem/LoadingSpinner'
import useModal from '../../../hooks/useModal'
import { ConfirmModal } from '../../../elem/Modal'
import { FaUndoAlt } from 'react-icons/fa'
import { DefaultTooltip } from '../../../elem/Tooltip'

const UndoAction = ({ lastRow, updateGrid, config }) => {
    const { isShowing, toggle } = useModal()
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { process } = useContext(CurrentProcessContext)
    const [loading, setLoading] = useState(false)
    const { API_URL } = config

    const lastAction = lastRow.original

    const undoTransition = () => {
        if (!lastAction.ExternalDataItemId || !process.ProcessId) {
            console.log(
                lastAction.ExternalDataItemId,
                process.ProcessId,
                'error'
            )
        } else {
            const POST = (action) => {
                const formData = new FormData()
                formData.append('ProcessId', action.ProcessId)
                formData.append('ExternalDataItemId', action.ExternalDataItemId)
                return {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        // 'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers':
                            'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                    },
                    body: formData,
                }
            }

            setLoading(true)
            authenticatedFetch(
                `${API_URL}/Process/undoTransition`,
                POST({
                    ProcessId: process.ProcessId,
                    ExternalDataItemId: lastAction.ExternalDataItemId,
                })
            )
                .then(async (response) => {
                    if (response.ok) {
                        return response
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(() => {
                    updateGrid(
                        `subGrid_${lastRow.index}_${lastAction.ExternalDataItemId}`
                    )
                })
                .catch((e) => {
                    toast({
                        level: 'error',
                        message:
                            'Workflow Landing Page:' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server'),
                    })
                })
                .finally(() => {
                    toggle()
                    setLoading(false)
                })
        }
    }

    const confirmation = isShowing ? (
        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
            <p>Are you sure you want to undo {lastAction.StartStateName}?</p>
        </div>
    ) : null

    return (
        <>
            <div className="undo">
                <div
                    className="undoIcon"
                    data-tip={`Undo Last Action`}
                    data-for="undo"
                    style={{ fontSize: '1.25em' }}
                >
                    {loading === true ? (
                        <LoadingSpinner size="1.25em" />
                    ) : (
                        <FaUndoAlt onClick={toggle} />
                    )}
                    <DefaultTooltip id="undo" place={'right'} type={'dark'} />
                </div>
                {isShowing ? (
                    <ConfirmModal
                        confirmation={confirmation}
                        cancel={toggle}
                        submit={undoTransition}
                        className="undoAction"
                    />
                ) : null}
            </div>
        </>
    )
}

export default withConfig(UndoAction)
