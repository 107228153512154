import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useMemo,
} from 'react'
import { ProcessContext } from './ProcessContext'
import withConfig from './withConfig'
import toast from '../elem/Toast'
import { useParams } from 'react-router-dom'
import { APIRequestContext } from './APIRequestWithToken'
import addAttributes from '../../utils/addAttributes'

const CurrentProcessContext = createContext(null)

const CurrentProcessContextProvider = ({ config, children }) => {
    const { processes } = useContext(ProcessContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { API_URL } = config
    const { processName } = useParams()
    const [transitions, setTransitions] = useState([])
    const [externalDataItems, setExternalDataItems] = useState([])
    const [lastActions, setLastActions] = useState([])
    const [loading, setLoading] = useState(true)
    const [action, setAction] = useState(null)
    const [editDate, setEditDate] = useState({})
    const [gridConfigExpanded, setGridConfigExpanded] = useState(false)

    const process = useMemo(() => {
        if (processes.length) {
            return processes.filter(
                (process) =>
                    process.ProcessName && process.ProcessName === processName
            )[0]
        }
    }, [processes, processName])

    const processSubTypeColumn = useMemo(() => {
        if (process) {
            return process.ProcessSubType
        }
    }, [process])

    const processSubTypes = useMemo(() => {
        if (
            externalDataItems &&
            externalDataItems.length &&
            processSubTypeColumn
        ) {
            return [
                ...new Set(
                    externalDataItems.map(
                        (x) => x.AttributeJSON[processSubTypeColumn]
                    )
                ),
            ].filter((x) => !!x).sort()
        } else {
            return []
        }
    }, [externalDataItems, processSubTypeColumn])

    useEffect(() => {
        if (process && !transitions.length) {
            authenticatedFetch(
                `${API_URL}/Process/transitionsbyprocessid/${process.ProcessId}`
            )
                .then(async (response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then((response) => {
                    setTransitions(response)
                })
                .catch((e) => {
                    toast({
                        level: 'error',
                        message:
                            'Workflow Landing Page:' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server'),
                    })
                })
        }
    }, [API_URL, process])

    useEffect(() => {
        if (process && (!externalDataItems.length || action)) {
            setLoading(true)
            authenticatedFetch(
                `${API_URL}/Process/externaldatabyprocessid/${process.ProcessId}`
            )
                .then(async (response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then((response) => {
                    setExternalDataItems(
                        addAttributes(response)
                    )
                })
                .catch((e) => {
                    toast({
                        level: 'error',
                        message:
                            'Workflow Landing Page:' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server'),
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [API_URL, process, action])

    useEffect(() => {
        if (process && (!lastActions.length || action)) {
            setLoading(true)
            authenticatedFetch(
                `${API_URL}/Process/lastActionsByProcessId/${process.ProcessId}`
            )
                .then(async (response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then((response) => {
                    setLastActions(response)
                })
                .catch((e) => {
                    toast({
                        level: 'error',
                        message:
                            'Workflow Landing Page:' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server'),
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [API_URL, process, action])

    useEffect(() => {
        if (Object.keys(editDate).length) {
            const PUT = (edited) => {
                return {
                    method: 'PUT',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers':
                            'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                    },
                    body: JSON.stringify(edited),
                }
            }

            setLoading(true)
            const { ExternalDataItemId, ...editObject } = editDate
            Promise.all(
                editDate.ExternalDataItemId.map((x) =>
                authenticatedFetch(
                        `${API_URL}/Process/editDataItem/${x}`,
                        PUT(editObject)
                    )
                        .then(async (response) => {
                            if (response.ok) {
                                return response
                            } else {
                                const error = await response.text()
                                throw new Error(error)
                            }
                        })
                        .catch((e) => {
                            toast({
                                level: 'error',
                                message:
                                    'Workflow Landing Page:' +
                                    (e.message
                                        ? e.message
                                        : 'Unable to connect to the server'),
                            })
                        })
                )
            ).then(() => {
                setEditDate({})
                setLoading(false)
            })
        }
    }, [editDate, API_URL])

    const headers = useMemo(
        () =>
            externalDataItems && externalDataItems.length
                ? Object.keys(externalDataItems[0]).filter((item) =>
                      Object.keys(externalDataItems[0].AttributeJSON).includes(
                          item
                      )
                  )
                : [],
        [externalDataItems]
    )

    return (
        <CurrentProcessContext.Provider
            value={{
                process,
                transitions,
                externalDataItems,
                action,
                setAction,
                setEditDate,
                loading,
                headers,
                lastActions,
                gridConfigExpanded,
                setGridConfigExpanded,
                processSubTypes,
                processSubTypeColumn
            }}
        >
            {children}
        </CurrentProcessContext.Provider>
    )
}

export { CurrentProcessContext }
export default withConfig(CurrentProcessContextProvider)
