import React, { useContext, useEffect, useState } from 'react'
import withConfig from '../../../wrappers/withConfig'
import toast from '../../../elem/Toast'
import SubGrid from './SubGrid'
import LoadingSpinner from '../../../elem/LoadingSpinner'
import { APIRequestContext } from '../../../wrappers/APIRequestWithToken'


const SubGridData = ({ id, setActioned, config }) => {
    const [loading, setLoading] = useState(true)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { API_URL } = config
    const [data, setData] = useState([])
    const [undoRow, setUndoRow] = useState(null)

    const updateGrid = (id) => {
        setActioned(id)
        setUndoRow(id)
    }

    useEffect(() => {
        setLoading(true)
        authenticatedFetch(`${API_URL}/Process/ExternalDatesByDataItemId/${id}`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setData(response)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Workflow Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [API_URL, id, undoRow])


    return loading ? <div className="is-flex is-justify-content-center is-align-items-flex-end"><LoadingSpinner size="2.5em"/></div> : <SubGrid externalItemDates={data} id={id} loading={loading} updateGrid={updateGrid}/>
}

export default withConfig(SubGridData)
