import React, { useContext, useState } from 'react'
import { UserContext } from '../../wrappers/UserContext'
import urls from '../../../utils/constants/urls'
import { hasAccessToAdmin } from '../../../utils/user/permissions'
import withConfig from '../../wrappers/withConfig'
import NavbarBurger from './NavbarBurger'

const NavBar = withConfig(({ config, title }) => {
    const { client, roles } = useContext(UserContext)
    const [burgerExpanded, setBurgerExpanded] = useState(false)
    const { CONFIGURED_URLS } = config

    return (
        <nav
            className="navbar is-transparent is-fixed-top"
            role="navigation"
            aria-label="main navigation"
        >
            <div className="navbar-brand title is-size-5">
                <a className="navbar-item" href={urls.home}>
                    {title}
                </a>
                <NavbarBurger
                    burgerExpanded={burgerExpanded}
                    setBurgerExpanded={setBurgerExpanded}
                />
            </div>
            <div className={`navbar-menu ${burgerExpanded ? 'is-active' : ''}`}>
                <div className="navbar-end">
                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link navbarButton">Account</a>
                        <div className="navbar-dropdown is-right">
                            {roles && hasAccessToAdmin(roles) && (
                                <a
                                    href={CONFIGURED_URLS.manageUsers}
                                    className="navbar-item navbarButton"
                                    target="_blank"
                                >
                                    Manage Users
                                </a>
                            )}
                            <a
                                className="navbar-item navbarButton"
                                href={CONFIGURED_URLS.changePassword}
                                target="_blank"
                            >
                                Change Password
                            </a>
                            <button
                                className="navbar-item navbarButton"
                                onClick={function () {
                                    client.signoutRedirect()
                                }}
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
})

export default NavBar
