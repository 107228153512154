import React from 'react'

export default ({ burgerExpanded, setBurgerExpanded }) => {
    return (
        <a
            role="button"
            className={`navbar-burger ${burgerExpanded ? 'is-active' : ''}`}
            aria-label="menu"
            aria-expanded={burgerExpanded}
            onClick={() => setBurgerExpanded(!burgerExpanded)}
        >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    )
}
