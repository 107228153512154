import React, { useCallback, useContext } from 'react'
import { FaCog } from 'react-icons/fa'
import { CurrentProcessContext } from '../../wrappers/CurrentProcessContext'

const GridConfig = () => {
    const { gridConfigExpanded, setGridConfigExpanded } = useContext(
        CurrentProcessContext
    )

    const onClick = useCallback(() => {
        setGridConfigExpanded(!gridConfigExpanded)
    }, [gridConfigExpanded, setGridConfigExpanded])

    return (
        <div
            className={`controlButtonWrapper `}
            onClick={() => onClick()}
        >
            <div className={`gridConfigButton is-size-65 ${
                gridConfigExpanded ? 'active' : ''
            }`}>
                <FaCog />
            </div>
        </div>
    )
}

export default GridConfig
