import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useMemo,
} from 'react'
import withConfig from './withConfig'
import toast from '../elem/Toast'
import { APIRequestContext } from './APIRequestWithToken'
import { UserContext } from './UserContext'

const NoteContext = createContext(null)

const NoteContextProvider = ({ config, children }) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { user } = useContext(UserContext)
    const [notes, setNotes] = useState([])
    const [noteTypes, setNoteTypes] = useState([])
    const [comment, setComment] = useState(null)
    const [reload, setReload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [itemId, setItemId] = useState(null)

    const isSubNote = useMemo(() => itemId && itemId.Type === 'state', [itemId])

    const POST = (note) => {
        const formData = new FormData()
        formData.append('ExternalDataItemId', note.ExternalDataItemId)
        formData.append('NoteSubject', note.NoteSubject)
        formData.append('NoteBody', note.NoteBody)
        formData.append('NoteType', note.NoteType)
        formData.append('NoteDate', note.NoteDate)
        formData.append('ModifyDate', note.ModifyDate)
        formData.append('UserId', note.UserId)
        formData.append('ModifyUser', note.ModifyUser)
        if (note.ExternalDataItemProcessStateId) {
            formData.append(
                'ExternalDataItemProcessStateId',
                note.ExternalDataItemProcessStateId
            )
        }
        return {
            method: 'POST',
            mode: 'cors',
            headers: {
                // 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: formData,
        }
    }

    const PUT = (edited) => {
        return {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(edited),
        }
    }

    useEffect(() => {
        if (comment && comment.Type === 'New') {
            const newNote = comment.Note
            const stateId = isSubNote
                ? {
                      ExternalDataItemProcessStateId:
                          itemId.ExternalDataItemProcessStateId,
                  }
                : {}

            setLoading(true)
            authenticatedFetch(
                `${API_URL}/Process/${
                    isSubNote ? 'createSubNote' : 'createNote'
                }`,
                POST({
                    ...stateId,
                    ExternalDataItemId: itemId.ExternalDataItemId,
                    NoteSubject: newNote.NoteSubject,
                    NoteBody: newNote.NoteBody,
                    NoteDate: newNote.NoteDate,
                    ModifyDate: newNote.ModifyDate,
                    NoteType: newNote.NoteType,
                    UserId: 1, //
                    ModifyUser: user && user.profile && user.profile.name,
                })
            )
                .then(async (response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .catch((e) => {
                    toast({
                        level: 'error',
                        message:
                            'Workflow Landing Page:' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server'),
                    })
                })
                .finally(() => {
                    setLoading(false)
                    setComment(false)
                })
        }
        if (comment && comment.Type === 'Edit') {
            const editNote = comment.Note
            setLoading(true)
            authenticatedFetch(
                `${API_URL}/Process/editNote/${editNote.NoteId}`,
                PUT(editNote)
            )
                .then(async (response) => {
                    if (response.ok) {
                        return response
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .catch((e) => {
                    toast({
                        level: 'error',
                        message:
                            'Workflow Landing Page:' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server'),
                    })
                })
                .finally(() => {
                    setLoading(false)
                    setComment(false)
                })
        }
    }, [comment])

    useEffect(() => {
        setLoading(true)
        authenticatedFetch(`${API_URL}/Process/noteTypes`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setNoteTypes(response)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Workflow Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [API_URL])

    useEffect(() => {
        if (itemId) {
            const url = isSubNote
                ? `${API_URL}/Process/GetNotesByStateId/${itemId.ExternalDataItemId}/${itemId.ExternalDataItemProcessStateId}`
                : `${API_URL}/Process/GetNotesByItemId/${itemId.ExternalDataItemId}`
            setLoading(true)
            authenticatedFetch(url)
                .then(async (response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then((response) => {
                    setNotes(response)
                })
                .catch((e) => {
                    toast({
                        level: 'error',
                        message:
                            'Workflow Landing Page:' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server'),
                    })
                })
                .finally(() => {
                    setLoading(false)
                    setReload(false)
                })
        }
    }, [API_URL, itemId, comment, reload])

    return (
        <NoteContext.Provider
            value={{
                loading,
                notes,
                setItemId,
                noteTypes,
                setComment,
                setReload,
            }}
        >
            {children}
        </NoteContext.Provider>
    )
}

export { NoteContext }
export default withConfig(NoteContextProvider)
