const getRoles = user => {
    if (user && user.profile && user.profile.role) {
        const roles = user.profile.role
        return roles
    } else {
        return []
    }
}

const checkRole = (role, roles) => {
    if (roles && roles.length && role) {
        if (role == 'Administrator') {
            return roles.some(x => x.includes(role))
        }
        return roles.some(x => x == role)
    } else {
        return false
    }
}

const hasAccessToAdmin = roles => checkRole('Administrator', roles)

export {
    getRoles,
    checkRole,
    hasAccessToAdmin,
}
