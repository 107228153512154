import React from 'react'
import ReactTooltip from 'react-tooltip'

const Tooltip = ({ id, extraClass, stayOpen, place, type }) => {
    return (
        <ReactTooltip
            id={id}
            className={`tooltipHover ${extraClass ? extraClass : ''}`}
            place={place ? place : 'right'}
            delayHide={stayOpen ? 250 : 0}
            delayShow={250}
            effect="solid"
            html={true}
            aria-haspopup="true"
            type={type ? type : "light"}
        />
    )
}

const DefaultTooltip = ({ id, className, place, type }) => (
    <Tooltip extraClass={className} id={id} stayOpen={true} place={place} type={type}/>
)

const IconTooltip = ({ id, place }) => (
    <Tooltip
        id={id}
        extraClass={'iconTooltip'}
        stayOpen={false}
        place={place}
    />
)

export default DefaultTooltip

export { DefaultTooltip, IconTooltip, Tooltip }
