import dayjs from 'dayjs'

const addAttributes = (response) =>
    response
        .map((item) => {
            const maxDuration = dayjs(item.DueDate).diff(
                dayjs(item.StartDate),
                'day'
            )
            const time =
                (new Date(item.DueDate).getTime() - new Date().getTime()) /
                84000000

            let status
            if (time > maxDuration * 0.33) {
                status = 'Med'
            }
            if (time > maxDuration * 0.66) {
                status = 'Low'
            }
            if (time < maxDuration * 0.33) {
                status = 'High'
            }
            if (Math.sign(time) === -1) {
                status = 'Overdue'
            }
            if (item.StateMaxDurationDays < 0) {
                status = 'Resolved'
            }
            return { ...item, Status: status }
        })
        .map((x) => {
            if (x.AttributeJSON) {
                return {
                    ...x.AttributeJSON,
                    ...x
                }
            } else {
                return x
            }
            // if(x.AttributeJSON){
            //     Object.keys(x.AttributeJSON).filter((y) =>
            //     Object.keys(columns).includes(y)
            //         ? (x[y] = x.AttributeJSON[y])
            //         : x
            // )}
            // return x
        })

export default addAttributes
