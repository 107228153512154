const getSubTypeConfigFromProcess = (process, filters, configField) => {
    if (process) {
        if (filters.ProcessSubType && process[configField]) {
            if (
                Object.keys(process[configField]).includes(
                    filters.ProcessSubType
                )
            ) {
                return process[configField][filters.ProcessSubType]
            }
        }
        return process[configField] ? process[configField].Default : null
    } else {
        return null
    }
}

const getTransitionsForExternalDataItem = (
    row,
    processSubTypeColumn,
    transitions
) => {
    const stateId = row.original.StateId
    const processSubType = row.original[processSubTypeColumn]
    const relatedTransitions = transitions.filter(
        (transition) => transition.CurrentStateId === Number(stateId)
    )
    const allowedTransitions = relatedTransitions.filter(transition => {
        const transitionProcessSubTypes = transition.ProcessSubTypes
        if (transitionProcessSubTypes) {
            const processSubTypesInclude = transitionProcessSubTypes.ProcessSubTypesInclude
            const processSubTypesExclude = transitionProcessSubTypes.ProcessSubTypesExclude
            // if the transition has processSubTypesExclude that includes this data item's process
            // subtype, remove the transition from the list
            if (processSubTypesExclude) {
                return !processSubTypesExclude.includes(processSubType)
            }
            // if the transition has processSubTypesInclude that does not include this data item's process subtype,
            // remove this transition from the list
            if (processSubTypesInclude) {
                return processSubTypesInclude.includes(processSubType)
            }
        }
        
        // if the transition does not have any data in the processSubTypes column, it is included for all data items
        return true;
        
    })
    return allowedTransitions
}

export { getSubTypeConfigFromProcess, getTransitionsForExternalDataItem }
