import React, { useRef, useEffect, useState, useContext, useMemo } from 'react'
import {
    filterColors,
} from '../../../../utils/StatusColors'
import { FaTimes } from 'react-icons/fa'
import { DefaultTooltip } from '../../../elem/Tooltip'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { FilterContext } from '../../../wrappers/FilterContext'
import LoadingSpinner from '../../../elem/LoadingSpinner'
import StatusBar from './Filters/StatusBar'
import ProcessSubTypeBar from './Filters/ProcessSubTypeBar'
import AttributeBarFilters from './Filters/AttributeBarFilters'

const BarFilters = ({ groups }) => {
    const [minWidth, setMinWidth] = useState(4)
    const { setClear } = useContext(ParameterContext)
    const { filters, setFilters, subTypeData, handleClearAllFilters } = useContext(FilterContext)

    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            if (
                [...ref.current.children]
                    .map((x) => x.offsetWidth)
                    .reduce((a, b) => a + b, 0) >
                ref.current.offsetWidth + 1
            ) {
                setMinWidth(minWidth - 1)
            }
        }
    }, [ref])

    const handleOuterDivClick = (e, filter) => {
        if (
            e.target.className.includes('inner') ||
            e.target.localName === 'h6'
        ) {
        } else {
            if (filter === filters.ActionGroup) {
                setFilters({ ...filters, StateName: null, ActionGroup: null })
            } else {
                setFilters({ ...filters, StateName: null, ActionGroup: filter })
            }
        }
    }

    const handleInnerDivClick = (e, group, filter) => {
        if (filter === filters.StateName) {
            setFilters({ ...filters, ActionGroup: null, StateName: null })
        } else {
            setFilters({ ...filters, ActionGroup: group, StateName: filter })
        }
    }

    const innerClass = (state) => {
        return !filters.StateName
            ? 'innerBar'
            : filters.StateName === state
            ? 'innerBar selected'
            : 'innerBar inactive'
    }
    const outerClass = (group) => {
        return !filters.ActionGroup || filters.ActionGroup === group
            ? 'group'
            : 'group inactive'
    }

    const data = useMemo(() => groups.map((group) => {
        const stateArr = subTypeData
            .filter((x) => x.ActionGroup === group)
            .sort((a, b) => a.StateId - b.StateId)

        const stateGroups = stateArr.length
            ? stateArr.reduce((acc, curr) => {
                  !acc[curr.StateName]
                      ? (acc[curr.StateName] = 1)
                      : acc[curr.StateName]++
                  return acc
              }, {})
            : {}
        return {
            ActionGroup: group,
            Groups: stateGroups,
            Colors: Object.values(stateGroups).length,
            Total: stateArr.length
                ? Object.values(stateGroups).reduce((acc, curr) => acc + curr)
                : 0,
        }
    }), [groups, subTypeData])

    return (
        <div className="filterSection">
            <div className="is-size-5 is-right">
                <button
                    className={'button is-small is-inverted'}
                    style={{ marginLeft: '1%' }}
                    onClick={() => handleClearAllFilters(setClear)}
                >
                    Clear All Filters
                </button>
            </div>
            <ProcessSubTypeBar />
            <div className="actionGroups">
                {!data.length ? (
                    <LoadingSpinner />
                ) : (
                    data.map((action, i) => {
                        const actionId = i > 0 ? data[i - 1].Colors : 0
                        const states = Object.entries(action.Groups)
                        return (
                            <div
                                className={outerClass(action.ActionGroup)}
                                onClick={(e) =>
                                    handleOuterDivClick(e, action.ActionGroup)
                                }
                                key={`${i}_${actionId}`}
                            >
                                <h5 className="title is-6">
                                    {action.ActionGroup}
                                </h5>
                                <div className="box">
                                    <div
                                        className="outerBar"
                                        ref={
                                            action.ActionGroup === 'Agency'
                                                ? ref
                                                : null
                                        }
                                    >
                                        {!states.length &&
                                        !subTypeData.length ? (
                                            <div className="spinnerContainer">
                                                <LoadingSpinner size="2em" />
                                            </div>
                                        ) : states.length ? (
                                            states.map((arr, i) => {
                                                return (
                                                    <div
                                                        className={innerClass(
                                                            arr[0]
                                                        )}
                                                        onClick={(e) =>
                                                            handleInnerDivClick(
                                                                e,
                                                                action.ActionGroup,
                                                                arr[0]
                                                            )
                                                        }
                                                        key={`${action.ActionGroup}_${i}`}
                                                        style={{
                                                            width: `${
                                                                (arr[1] /
                                                                    action.Total) *
                                                                100
                                                            }%`,
                                                            minWidth: `${minWidth}vw`,
                                                            backgroundColor:
                                                                filterColors[
                                                                    i + actionId
                                                                ],
                                                        }}
                                                    >
                                                        <h6 className="is-size-6 has-text-black total">
                                                            {arr[1]}
                                                        </h6>

                                                        <h6 className="is-size-7 has-text-black">
                                                            {arr[0]}
                                                        </h6>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div
                                                className="innerBar inactive"
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <h6 className="is-size-6 has-text-black total">
                                                    0
                                                </h6>

                                                <h6 className="is-size-7 has-text-black">
                                                    None
                                                </h6>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )}
            </div>
            <AttributeBarFilters />
            <StatusBar data={subTypeData} />
        </div>
    )
}

export default BarFilters
