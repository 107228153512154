import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import withConfig from '../../../wrappers/withConfig'
import toast from '../../../elem/Toast'
import { CurrentProcessContext } from '../../../wrappers/CurrentProcessContext'
import LoadingSpinner from '../../../elem/LoadingSpinner'
import useModal from '../../../hooks/useModal'
import { ConfirmModal } from '../../../elem/Modal'
import { DateSelector } from '../../../elem/Selectors'
import { FaEdit } from 'react-icons/fa'
import {
    dateToString,
    saveDate,
    daysUntilDate,
    dateAfterDays,
    setTimeToDefault,
} from '../../../../utils/formatDate'
import { APIRequestContext } from '../../../wrappers/APIRequestWithToken'
import { UserContext } from '../../../wrappers/UserContext'

const GroupActionSelect = ({ groupActions, transitions, config }) => {
    const { isShowing, toggle } = useModal()
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { setAction, setEditDate } = useContext(CurrentProcessContext)
    const { user } = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const { API_URL } = config
    const [options, setOptions] = useState([])
    const [option, setOption] = useState(null)
    const [editDur, setEditDur] = useState(false)
    const [calendarOpen, setCalendarOpen] = useState(false)
    const [ids, setIds] = useState(null)
    const [selectDefault, setSelectDefault] = useState(true)
    const [complianceAchievedDate, setComplianceAchievedDate] = useState(null)

    const [date, setDate] = useState(null)
    const [duration, setDuration] = useState(null)
    const [originalDate, setOriginalDate] = useState(null)
    const selectRef = useRef()

    useEffect(() => {
        if (groupActions.Actions) {
            setOptions(
                groupActions.Actions.map((action) => {
                    const Transition = transitions.filter(
                        (transition) =>
                            transition.ProcessStateTransitionId === action
                    )[0]
                    return Transition
                        ? {
                              Name: Transition.TransitionName,
                              Value: action,
                              Current: Transition.CurrentState,
                              Next: Transition.NextStateId,
                              NextName: Transition.NextState
                          }
                        : []
                })
            )
            setIds(groupActions.Ids)
        }
        if (!groupActions.Actions) {
            setOptions([])
            setSelectDefault(true)
        }
    }, [groupActions, groupActions.Actions, transitions])

    useEffect(() => {
        if (isShowing) {
            const next = transitions.filter(
                (x) => x.CurrentStateId === option.Next
            )
            const nextDueDate = next.map((y) =>
                dateAfterDays(y.StateMaxDurationDays)
            )[0]

            if (nextDueDate && next[0]) {
                setOriginalDate(nextDueDate)
                setDate(nextDueDate)
                setDuration(next[0].StateMaxDurationDays)
            } else {
                setDate(null)
            }
        }
    }, [isShowing, transitions, option])

    const handleChange = useCallback((e) => {
        setSelectDefault(false)
        const selectedOption = options.filter(
            (item) => item.Value === Number(e.target.value)
        )[0]
        if (selectedOption) {
            setOption(selectedOption)
        }
    }, [options])

    useEffect(() => { //get the currently selected option from the ref if the option state variable doesn't match with the currently selected dropdown item 
        const currentSelectValue = selectRef.current.value //this is the currently selected dropdown value 
        if (option && option.Value !== currentSelectValue) {
            const selectedOption = options.filter(
                (item) => item.Value === Number(currentSelectValue)
            )[0]
            if (selectedOption) {
                setOption(selectedOption)
            }   
        }
    }, [options, selectRef, option])

    const POST = (action) => {
        const formData = new FormData()
        formData.append('ProcessId', action.ProcessId)
        formData.append('ExternalDataItemIds', action.ExternalDataItemIds)
        formData.append('TransitionId', action.TransitionId)
        formData.append('ModifyUser', action.ModifyUser)
        return {
            method: 'POST',
            mode: 'cors',
            headers: {
                // 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: formData,
        }
    }
    const executeTransitions = () => {
        if (!ids || !option || !option.Value) {
            console.log(ids, option.Value, 'error')
        }
        setLoading(true)
        authenticatedFetch(
            `${API_URL}/Process/executeTransitions`,
            POST({
                ProcessId: transitions[0].ProcessId,
                ExternalDataItemIds: ids.toString().replaceAll(',', '|'),
                TransitionId: option.Value,
                ModifyUser: user && user.profile && user.profile.name
            })
        )
            .then(async (response) => {
                if (response.ok) {
                    return response
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(() => {
                if (date && dateToString(originalDate) !== dateToString(date)) {
                    setEditDate({
                        StartDate: setTimeToDefault(saveDate()),
                        DueDate: setTimeToDefault(date),
                        ExternalDataItemId: ids,
                        ComplianceAchievedDate: null
                    })
                }
                else if (complianceAchievedDate) {
                    setEditDate({
                        StartDate: setTimeToDefault(saveDate()),//saveDate(),
                        DueDate: null,
                        ExternalDataItemId: ids,
                        ComplianceAchievedDate: setTimeToDefault(saveDate(complianceAchievedDate))
                    })
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Workflow Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(() => {
                setAction(`${option.Value}_${ids}`)
                setTimeout(() => setLoading(false), 1500)
                setComplianceAchievedDate(null)
                toggle()
            })
    }

    const cancel = () => {
        setComplianceAchievedDate(null)
        toggle()
    }

    const handleDateChange = (e) => {
        setDate(e)
        setDuration(daysUntilDate(e))
    }

    const handleDurationChange = (e) => {
        setDuration(Number(e.target.value))
        setDate(dateAfterDays(Number(e.target.value)))
    }

    const defaultSelectOption = useMemo(() => (
        <option
            key="placeholder"
            value=""
            disabled
            hidden
        >
            {!Object.keys(groupActions).length
                ? 'Select Items'
                : !options.length &&
                    Object.keys(groupActions).length
                ? 'Batch Actions Unavailable'
                : 'Batch Actions Available'}
        </option>
    ), [groupActions, options])

    const confirmation =
        option && option.Value && options.length > 0 && isShowing ? (
            <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                <p style={{textAlign: "center"}}>
                    Are you sure you want to move {ids.length} items to{' '}
                    {option.NextName} from {options[0].Current}?{' '}
                </p>
                {date ? (
                    <p className="actionDate">
                        `The next action will be due in{' '}
                        {editDur ? (
                            <input
                                className="duration input"
                                type="number"
                                classname="input"
                                defaultValue={duration}
                                onChange={handleDurationChange}
                                onBlur={() => setEditDur(false)}
                            />
                        ) : (
                            <span
                                className="duration"
                                onClick={() => setEditDur(true)}
                            >
                                {duration}
                                <FaEdit style={{ width: '.9em' }} />
                            </span>
                        )}{' '}
                        days on
                        {
                            <span>
                                <DateSelector
                                    onChange={handleDateChange}
                                    onFocus={() => setCalendarOpen(true)}
                                    onBlur={() => setCalendarOpen(false)}
                                    selected={date}
                                />
                                <FaEdit
                                    style={{
                                        width: '1em',
                                        display: `${
                                            calendarOpen ? 'none' : 'initial'
                                        }`,
                                    }}
                                />
                            </span>
                        }
                    </p>
                ) : (
                    <span className="actionDate" style={{textAlign: "center"}}>
                        <p>
                            Please ensure associated records (e.g., Well, Facility) are up-to-date in WPF
                            and that the Compliance Achieved Date is set in the date picker below before moving this record to an END status.
                        </p>
                        <DateSelector
                            onChange={e => setComplianceAchievedDate(e)}
                            onFocus={() => setCalendarOpen(true)}
                            onBlur={() => setCalendarOpen(false)}
                            selected={complianceAchievedDate}
                        />
                        <FaEdit
                            style={{
                                width: '1em',
                                display: `${
                                    calendarOpen ? 'none' : 'initial'
                                }`,
                            }}
                        />
                    </span>
                )}
            </div>
        ) : null

    return (
        <>
            <div
                className="selectSection"
                style={{ color: 'inherit !important' }}
            >
                <h3 className="title is-size-7">Actions</h3>

                <div className="selectAction">
                    <select
                        className="select is-small is-rounded"
                        style={{ width: '150px' }}
                        onChange={handleChange}
                        disabled={!Object.keys(groupActions).length}
                        defaultValue={""}
                        ref={selectRef}
                    >
                        {defaultSelectOption}
                        {options.map((option, i) => (
                            <option
                                key={`action_option_${i}`}
                                value={option.Value}
                            >
                                {option.Name}
                            </option>
                        ))}
                    </select>
                    <div className="batchActionButton">
                        {loading === true ? (
                            <LoadingSpinner size="1.5em" />
                        ) : (
                            <button
                                onClick={toggle}
                                className="button is-small"
                                disabled={
                                    !option ||
                                    !option.Value ||
                                    !ids ||
                                    !options.length
                                }
                            >
                                Go
                            </button>
                        )}
                    </div>
                    {isShowing && option.Value ? (
                        <ConfirmModal
                            confirmation={confirmation}
                            cancel={cancel}
                            submit={executeTransitions}
                            className="batchAction"
                        />
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default withConfig(GroupActionSelect)
