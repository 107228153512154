import React from 'react'

const Modal = ({ toggle, header, footer, response, body, className }) => {
    return (
        <>
            <div className={`${className} modal`} id="modal" />
            <div className="modal-background">
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">
                            {header ? header : null}
                        </p>
                        <button
                            className={response ? 'hidden' : 'delete'}
                            aria-label="close"
                            onClick={toggle}
                        ></button>
                    </header>
                    <div className="modal-card-body">{body}</div>
                    <footer className="modal-card-foot">
                        {footer ? footer : null}
                    </footer>
                </div>
            </div>
        </>
    )
}

const ConfirmModal = ({ confirmation, cancel, submit, className }) => {
    return (
        <>
            <div className={`confirm modal`} id="modal" />
            <div className="modal-background"></div>
            <div className={`modal-card confirm box ${className}`}>
                <div className="modal-card-body confirm">
                    <div style={{ marginBottom: '15px' }}>{confirmation}</div>
                    <div className="buttons is-centered">
                        <button
                            style={{ padding: '7px 15px' }}
                            className="button is-small is-primary"
                            onClick={submit}
                        >
                            Ok
                        </button>
                        <button
                            style={{ padding: '7px' }}
                            className="button is-small"
                            onClick={cancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

const InformModal = ({ information, cancel, className }) => {
    return (
        <>
            <div className={`confirm modal`} id="modal" />
            <div className="modal-background"></div>
            <div className={`modal-card confirm box ${className}`}>
                <div className="modal-card-body confirm">
                    <div style={{ marginBottom: '15px' }}>{information}</div>
                    <div className="buttons is-centered">
                        <button
                            style={{ padding: '7px 15px' }}
                            className="button is-small is-primary"
                            onClick={cancel}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ConfirmModal, Modal, InformModal }

export default Modal
