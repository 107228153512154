import React, { useState, useContext, useMemo, useRef, useEffect } from 'react'
import withConfig from '../../wrappers/withConfig'
import dayjs from 'dayjs'
import { FaInfoCircle } from 'react-icons/fa'
import NotesModal from '../../features/processPage/Notes/NotesModal'
import ActionSelect from '../../features/processPage/Actions/ActionSelect'
import { CurrentProcessContext } from '../../wrappers/CurrentProcessContext'
import DateEditCell from '../../features/processPage/Grid/DateEditCell'
import { FilterContext } from '../../wrappers/FilterContext'
import { getTransitionsForExternalDataItem } from '../../../utils/subTypeConfig'
import Portal from '../Portal'

const format = 'MM/DD/YYYY'
const dateToString = (date) =>
    date ? dayjs(date).format(format).toString() : null

const Cell = ({ value, type, column, columnObject, row }) => {
    const { Header: label  } = columnObject
    return column === 'DueDate' || column === 'DateComplianceRequired' ? (
        <DateEditCell value={value} row={row} column={column} label={label} />
    ) : (
        <div className="tableCell">
            {type === 'Date' && value
                ? dateToString(value)
                : type === 'String' && value && value.includes('<br/>')
                ? value.split('<br/>').map((line, idx) => <div key={idx}>{line}</div>)
                : value}
        </div>
    )
}

const NoteCell = ({ id }) => <NotesModal id={id} />

const ActionCell = ({ row, processSubTypeColumn }) => {
    const {ExternalDataItemId: externalDataItemId, StateMaxDurationDays: maxDuration } = row.original
    const { transitions } = useContext(CurrentProcessContext)

    const options = getTransitionsForExternalDataItem(row, processSubTypeColumn, transitions)
    return (
        <ActionSelect
            options={options.length ? options : null}
            maxDuration={maxDuration}
            dataId={externalDataItemId}
        />
    )
}

const InfoCell = ({ attributes, id }) => {
    const [popUp, setPopUp] = useState(false)
    const [isSticky, setIsSticky] = useState(false)
    const [coord, setCoord] = useState({})
    const tooltipRef = useRef(null)
    const tooltipBoxRef = useRef(null)

    const { processConfig } = useContext(FilterContext)

    useEffect(() => {
        if (popUp & !isSticky) {
            const measureTooltipSize = () => {
                if (tooltipRef.current && tooltipBoxRef.current) {
                    const tooltipDiv = tooltipRef.current.getBoundingClientRect();
                    const rect = tooltipBoxRef.current.getBoundingClientRect();
                    const offsetX = 30;
                    setCoord({
                        left: (tooltipDiv.left + tooltipDiv.width / 2) - rect.width - offsetX,
                        top: (tooltipDiv.top + tooltipDiv.height / 2) + window.scrollY - rect.height
                    });
                }
            };

            const delay = 100;
            const timer = setTimeout(measureTooltipSize, delay);

            return () => clearTimeout(timer);
        }
    }, [popUp, isSticky]);

    const rows = useMemo(
        () => (processConfig && processConfig.tooltipColumns ? Object.keys(processConfig.tooltipColumns) : []),
        [processConfig]
    )
    const exampleTooltip = useMemo(
        () => (processConfig && processConfig.tooltipColumns ? processConfig.tooltipColumns : {}),
        [processConfig]
    )

    if (!Object.keys(attributes).length) {
        return null
    }

    const handleMouseEnter = () => {
        setPopUp(true)
    }
    const handleMouseLeave = () => {
        setPopUp(false)
    }

    const toggleInfoCellSticky = () => {
        if (isSticky) {
            setPopUp(true)
        }
        setIsSticky(x => !x)
    }

    return (
        <>
            <div
                ref={tooltipRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={toggleInfoCellSticky}
            >
                <FaInfoCircle style={{ cursor: 'pointer' }} />
                <div className={!popUp ? 'hidden' : 'connect'}></div>
                <Portal> 
                    <div className={`infoBoxOverflowSize ${!popUp && !isSticky ? 'hidden' : 'popUp popUpBox'}`}
                        style={coord}
                        ref={tooltipBoxRef}
                    >
                        <ul className="tooltip-list">
                            {rows.map((key, index) => {
                                if (attributes[key] != undefined) {
                                    if (
                                        exampleTooltip[key].DataType === 'Hyperlink'
                                    ) {
                                        return (
                                            <li
                                                key={`tooltip-${index}`}
                                                className="tooltip-list-item has-text-7"
                                            >
                                                <label
                                                    style={{
                                                        marginBottom:
                                                            '0% !important',
                                                    }}
                                                >
                                                    {`${exampleTooltip[key].Label}: `}
                                                </label>
                                                <a
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href={attributes[key]}
                                                >
                                                    {attributes[key].slice(8, 31)}
                                                </a>
                                            </li>
                                        )
                                    }
                                    return (
                                        <li
                                            key={`tooltip-${index}`}
                                            className="tooltip-list-item has-text-7"
                                        >
                                            <label>
                                                {`${exampleTooltip[key].Label}: `}
                                            </label>
                                            <span className="info">
                                                {attributes[key]}
                                            </span>
                                        </li>
                                    )
                                }
                                return null
                            })}
                        </ul>
                    </div>
                </Portal>
            </div>
        </>
    )
}

export { Cell, NoteCell, ActionCell, InfoCell }
export default withConfig(DateEditCell)
