import React, { useContext } from 'react'
import {
    StatusColors,
    StatusBackgroundColors,
} from '../../../../../utils/StatusColors'
import LoadingSpinner from '../../../../elem/LoadingSpinner'
import { FilterContext } from '../../../../wrappers/FilterContext'

const StatusBar = ({ data }) => {
    const { filters, setFilters, statusCount } = useContext(FilterContext)

    const statusClass = (status) =>
        filters.Status && filters.Status.includes(status)
            ? `status ${status}`
            : `status inactive ${status}`

    const statusBar = Object.keys(StatusColors).map((status) => {
        const statusArr = statusCount.length
            ? statusCount.filter((x) => x.Status === status)
            : data.filter((x) => x.Status === status)

        return {
            Status: status,
            Color: StatusColors[status],
            Count: statusArr.length,
        }
    })

    const handleStatusClick = (e) => {
        const status = Object.keys(StatusColors).filter((status) =>
            e.target.className.includes(status)
        )[0]

        const statusFilterArray = filters.Status

        statusFilterArray.length && statusFilterArray.includes(status)
            ? statusFilterArray.splice(statusFilterArray.indexOf(status), 1)
            : statusFilterArray.push(status)

        if (status) {
            setFilters({
                ...filters,
                Status: statusFilterArray,
            })
        }
    }

    return (
        <div className="status is-flex is-flex-direction-column">
            <div className="box is-flex is-flex-direction-row">
                {statusBar.map((item, i) => (
                    <div
                        onClick={handleStatusClick}
                        className={statusClass(item.Status)}
                        value={item.Status}
                        style={{
                            height: '5vh',
                            width: '20%',
                            backgroundColor:
                                StatusBackgroundColors[item.Status],
                            color: item.Color,
                        }}
                        key={`${item.Status}_${i}`}
                    >
                        <h6 className={`is-size-6 ${item.Status}`}>
                            {item.Status}:{' '}
                        </h6>
                        <h6 className={`is-size-6 ${item.Status}`}>
                            {' '}
                            {data.length ? (
                                item.Count
                            ) : (
                                <LoadingSpinner size="1em" />
                            )}
                        </h6>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default StatusBar
