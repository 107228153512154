const calcAttFilters = (temp, data) => {
    const newData = temp
            Object.keys(temp).forEach((item)=> {
                if(temp[item] && temp[item].Type === 'String' && temp[item].Value === ''){
                    delete newData[item]
                    delete temp[item]
                }
                if(temp[item] && temp[item].Type === 'Number' ){
                    if(!temp[item].Min && !temp[item].Max){
                        delete newData[item]
                    }
                    else{
                        newData[item].Min = temp[item].Min 
                        newData[item].Max = temp[item].Max
                        
                    }
                }
                if(temp[item] && temp[item].Type === 'Date' ){
                    if(!temp[item].MinDate && !temp[item].MaxDate){
                        delete newData[item]
                    }
                    else{
                        newData[item].MinDate = temp[item].MinDate 
                        newData[item].MaxDate = temp[item].MaxDate
                    }
                }
            })
    return newData
}

export default calcAttFilters