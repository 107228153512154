/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
const DateSelector = ({
    selected,
    onChange,
    onBlur,
    onFocus,
    onSelect,
    placeholder,
}) => {
    const boundariesElement = onSelect ? 'scrollParent' : 'viewport'

    const autoFocus = onSelect ? true : false

    return (
        <DatePicker
            selected={selected}
            onSelect={onSelect}
            onChange={onChange}
            onCalendarClose={onBlur}
            onCalendarOpen={onFocus}
            placeholderText={placeholder ? placeholder : `Select Date`}
            autoFocus={autoFocus}
            className="input"
            showYearDropdown
            showMonthDropdown
            yearDropdownItemNumber={150}
            scrollableYearDropdown
            scrollableMonthDropdown
            autoComplete="off"
            inline={false}
            popperModifiers={{
                offset: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: boundariesElement,
                },
            }}
        />
    )
}
const DateRangeSelector = ({ setCriteria, criteria, label }) => {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    useEffect(() => {
        if (startDate || endDate) {
            setCriteria({
                ...criteria,
                [label]: { MinDate: startDate, MaxDate: endDate, Type: 'Date' },
            })
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (!Object.keys(criteria).length) {
            setStartDate(null)
            setEndDate(null)
        }
        if (criteria[label] && !startDate) {
            setStartDate(criteria[label].MinDate)
            setEndDate(criteria[label].MaxDate)
        }
    }, [criteria])

    return (
        <div className="is-flex is-flex-direction-row">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                isClearable
                placeholderText="Min Date"
                startDate={startDate}
                endDate={endDate}
                showYearDropdown
                showMonthDropdown
                yearDropdownItemNumber={50}
                scrollableYearDropdown
                scrollableMonthDropdown
                autoComplete="off"
                inline={false}
                popperModifiers={{
                    offset: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport',
                    },
                }}
            />
            <span className="is-size-7">to</span>

            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                isClearable
                placeholderText="Max date"
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                // dateFormat="MM/dd/yyyy"
                showYearDropdown
                showMonthDropdown
                yearDropdownItemNumber={50}
                // dropdownMode="select"
                scrollableYearDropdown
                scrollableMonthDropdown
                autoComplete="off"
                inline={false}
                popperModifiers={{
                    offset: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport',
                    },
                }}
            />
        </div>
    )
}

const NumberRangeSelector = ({ temp, setTemp, item }) => (
    <div>
        <input
            value={temp[item] ? temp[item].Min : ''}
            type="number"
            onChange={(e) => {
                const value = e.target.value === '' ? null : e.target.value

                temp[item]
                    ? setTemp({
                          ...temp,
                          [item]: {
                              ...temp[item],
                              Min: value,
                          },
                      })
                    : setTemp({
                          ...temp,
                          [item]: {
                              Min: value,
                              Type: 'Number',
                          },
                      })
            }}
            placeholder={`Min`}
            style={{
                width: '80px',
            }}
        />

        <span className="is-size-7">to</span>
        <input
            value={temp[item] ? temp[item].Max : ''}
            type="number"
            onChange={(e) => {
                const value = e.target.value === '' ? null : e.target.value

                temp[item]
                    ? setTemp({
                          ...temp,
                          [item]: {
                              ...temp[item],
                              Max: value,
                          },
                      })
                    : setTemp({
                          ...temp,
                          [item]: {
                              Max: value,
                              Type: 'Number',
                          },
                      })
            }}
            placeholder={`Max`}
            style={{
                width: '80px',
            }}
        />
    </div>
)

const StringInput = ({ temp, setTemp, item }) => (
    <input
        value={temp[item] ? temp[item].Value : ''}
        onChange={(e) => {
            setTemp({
                ...temp,
                [item]: {
                    Value: e.target.value,
                    Type: 'String',
                },
            })
        }}
        placeholder={`Search data...`}
    />
)

const SelectFilter = ({ temp, setTemp, item, options }) => {
    return (
        <select
            value={temp[item] ? temp[item].Value : ''}
            onChange={(e) => {
                setTemp({
                    ...temp,
                    [item]: {
                        Value: e.target.value,
                        Type: 'Combo',
                    },
                })
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

export {
    DateRangeSelector,
    DateSelector,
    NumberRangeSelector,
    StringInput,
    SelectFilter,
}

export default DateRangeSelector
