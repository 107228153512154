import localForage from 'localforage'
const getLayout = async (processId) => {
    return localForage.getItem(`id:${processId}`).then((cachedLayout) => {
        if (cachedLayout) {
            const json = JSON.parse(cachedLayout)
            if (json) {
                return json
            }
        }
        return
    })
}


const saveLayout = (processId, layout) => {
    localForage.setItem(`id:${processId}`, JSON.stringify(layout)) 
}

const clearLayout = (processId) => {
    localForage.removeItem(`id:${processId}`)
}

export { getLayout, saveLayout, clearLayout }