import React, { useContext, useState, useEffect, useMemo } from 'react'
import withConfig from '../../../wrappers/withConfig'
import toast from '../../../elem/Toast'
import LoadingSpinner from '../../../elem/LoadingSpinner'
import useModal from '../../../hooks/useModal'
// import { ConfirmModal } from '../../../elem/Modal'
// import { dateToString, saveDate, daysUntilDate, dateAfterDays} from '../../../../utils/formatDate'
import { APIRequestContext } from '../../../wrappers/APIRequestWithToken'
import { saveAs } from 'file-saver'
import { FilterContext } from '../../../wrappers/FilterContext'


const ReportSelect = ({ id, config }) => {
    const { 
        // isShowing, 
        toggle } = useModal()
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { processConfig } = useContext(FilterContext)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [option, setOption] = useState(null)
    const { API_URL } = config


    const reports = useMemo(
        () => (processConfig && processConfig.reports ? processConfig.reports : []),
        [processConfig]
    )

    useEffect(() => {
        if (id) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [id])

    const handleChange = (e) => {
        setOption(e.target.value)
    }

    const generateDocX = () => {
        if (!id || !option) {
            console.log(id, option, 'error')
        }

        setLoading(true) // set the loading to true
        authenticatedFetch(
            `${API_URL}/Report/GenerateDocX/${option.replace('.docx', '')}/${id}`
        )
            .then(async (response) => {
                if (response.ok) {
                    return response.blob()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Workflow Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .then((data) => {
                saveAs(data, `${option}`)
            })
            .finally(() => {
                toggle()
                setLoading(false)
            })
    }

    // const cancel = () => {
    //     toggle()
    // }


    return (
        <>
            <div
                className="selectSection"
                style={{ color: 'inherit !important' }}
            >
                <h3 className="title is-size-7">Reports</h3>
                <div className="selectAction">
                    <select
                        className="select is-small is-rounded"
                        style={{ width: '150px' }}
                        onChange={handleChange}
                        disabled={disabled}
                        defaultValue={""}
                    >
                        {
                            <option value="" disabled hidden>
                                {disabled
                                    ? 'Select One Item'
                                    : 'Generate Report'}
                            </option>
                        }
                        {Object.keys(reports).map((option, i) => (
                            <option key={`action_option_${i}`} value={option}>
                                {reports[option].Label}
                            </option>
                        ))}
                    </select>
                    <div>
                        {loading === true ? (
                            <span style={{color: "#d7e7f19c"}}>
                            <LoadingSpinner size="1.5em" />
                            </span>
                        ) : (
                            <button
                                onClick={generateDocX}
                                className="button is-small"
                                disabled={!option || disabled}
                            >
                                Go
                            </button>
                        )}
                    </div>
                    {/* {isShowing ? (
                        <ConfirmModal
                            // confirmation={confirmation}
                            cancel={cancel}
                            // submit={generateDocX}
                            className="singleAction"
                        />
                    ) : null} */}
                </div>
            </div>
        </>
    )
}

export default withConfig(ReportSelect)
