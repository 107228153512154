import React, { createContext, useCallback } from 'react'
import { useAuth } from 'oidc-react'
import withConfig from './withConfig'

const defaultOptions = {
    cache: 'default',
    headers: {
        CacheControl: 'max-age=3600'
    }
}

const APIRequestContext = createContext(null)

const APIRequestContextProvider = withConfig(({ config, children }) => {
    const { userManager } = useAuth()
    const { TOKEN } = config

    const authenticatedFetch = useCallback(async (endpoint, options) => {
        const u = await userManager.getUser()
        let fetchOptions = {}
        const authorizationHeader = u ? {
            Authorization: `Bearer ${u.access_token}`,
        } : {}
        if (options) {
            fetchOptions = {
                ...defaultOptions,
                ...options,
                headers: {
                    ...defaultOptions.headers,
                    ...options.headers,
                    ...authorizationHeader
                },
            }
        } else {
            fetchOptions = {
                method: 'GET',
                ...defaultOptions,
                headers: {
                    ...defaultOptions.headers,
                    ...authorizationHeader
                },
            }
        }
        return fetch(endpoint, fetchOptions)
    }, [userManager])


    const fetchWithToken = (endpoint, options) => {
        return  fetch(`${endpoint}?token=${TOKEN}`, options) 
    }

    return (
        <APIRequestContext.Provider
            value={{authenticatedFetch, fetchWithToken}}
        >
            {children}
        </APIRequestContext.Provider>
    )
})

export { APIRequestContext }
export default APIRequestContextProvider
