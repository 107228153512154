import React, { useContext } from 'react'
import { useLocation, Redirect, withRouter } from 'react-router-dom'
import { UserContext } from '../../wrappers/UserContext'
import withConfig from '../../wrappers/withConfig'

const Login = withConfig(withRouter(({ config, history }) => {
    const { user, client } = useContext(UserContext)
    const location = useLocation()
    const { CONFIGURED_URLS } = config

    if (location.pathname !== '/login') {
        history.replace('/login')
    }

    if (user) {
        return (
            <Redirect
                to={{
                    pathname: `/`,
                }}
            />
        )
    }

    return (
        <>
            <div className="section">
                <h2 className="title">Workflow Management</h2>
            </div>
            <div className="section">
                <div className="forms-section">
                    <section className="forms">
                        <div className={`form-wrapper`}>
                            <button
                                type="button"
                                className="button switcher switcher-login title is-3"
                                onClick={function() {client.signinRedirect()}}
                            >
                                Login
                            </button>
                        </div>
                        <div className={`form-wrapper`}>
                            <a 
                                href={CONFIGURED_URLS.newRegistration}
                                target="_blank"
                                rel="opener"
                            >
                                <button
                                    type="button"
                                    className="button switcher switcher-signup title is-3"
                                >
                                    Register
                                </button>
                            </a>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}))

export default Login
