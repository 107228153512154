import React, { useContext, useCallback, useMemo } from 'react'
import LoadingSpinner from '../../../../elem/LoadingSpinner'
import cloneDeep from 'lodash/cloneDeep'
import { filterColors } from '../../../../../utils/StatusColors'
import { CurrentProcessContext } from '../../../../wrappers/CurrentProcessContext'
import { FilterContext } from '../../../../wrappers/FilterContext'

const AttributeBarFilters = () => {
    const { externalDataItems } = useContext(CurrentProcessContext)
    const {
        filters,
        setFilters,
        setCriteria,
        criteria,
        barFilter,
        processConfig: { attributeColumns: attributeBarFilterGroups },
    } = useContext(FilterContext)

    const handleInnerDivClick = useCallback(
        (barFilterColumn, barFilterValue) =>
            setCriteria((prevCriteria) => {
                if (Object.keys(prevCriteria).includes(barFilterColumn) && prevCriteria[barFilterColumn].Value === barFilterValue) {
                    const p = cloneDeep(prevCriteria)
                    delete p[barFilterColumn]
                    return p
                } else {
                    return {
                        ...prevCriteria,
                        [barFilterColumn]: {
                            Value: barFilterValue,
                            Type: 'Combo',
                        }
                        
                    }
                }
            }),
        [filters, setFilters, setCriteria]
    )

    const handleOuterDivClick = useCallback(
        (groupName, e) => {
            if (
                !(
                    e.target.className.includes('inner') ||
                    e.target.localName === 'h6'
                )
            ) {
                setCriteria((prevCriteria) => {
                    const p = cloneDeep(prevCriteria)
                    delete p[groupName]
                    return p
                })
            }
        },
        [filters, setCriteria]
    )

    const attributeFilterData = useMemo(() => {
        if (
            attributeBarFilterGroups &&
            attributeBarFilterGroups.length &&
            barFilter &&
            barFilter.length
        ) {
            return attributeBarFilterGroups.map((x) => {
                const filterConfig = x
                const columnName = x.ColumnName
                const barFilterOptions = [
                    ...new Set(barFilter.map((x) => x[columnName])),
                ]
                const associatedData = barFilterOptions.map((y) => {
                    const count = barFilter.filter((z) => z[columnName] === y)
                        .length
                    return {
                        label: y,
                        count,
                    }
                })

                return {
                    ...filterConfig,
                    barFilterData: associatedData,
                }
            })
        }
    }, [barFilter, attributeBarFilterGroups])

    const activeCriteria = useMemo(() => {
        if (attributeFilterData) {
            return attributeFilterData.map(barData => {
                const columnName = barData.ColumnName
                if (!Object.keys(criteria).includes(columnName)) {
                    return {
                        filterColumn: columnName,
                        activeValue: null
                    }
                } else {
                    const associatedCriteria = criteria[columnName]
                    const activeValue = associatedCriteria.Value
                    return {
                        filterColumn: columnName,
                        activeValue
                    }
                }
            })
        }
        return []
    }, [attributeFilterData, criteria])

    if (!attributeFilterData || typeof attributeFilterData === 'undefined') {
        return null
    }
    return (
        <div className="actionGroups attributes">
            {attributeFilterData.map((group) => {
                return (
                    <div
                        className={`group`}
                        onClick={handleOuterDivClick.bind(
                            this,
                            group.ColumnName
                        )}
                    >
                        <h5 className="title is-6">{group.Label}</h5>
                        <div className="box">
                            <div className="outerBar">
                                {!group.barFilterData.length ? (
                                    <div className="spinnerContainer">
                                        <LoadingSpinner size="2em" />
                                    </div>
                                ) : (
                                    group.barFilterData.map((d, i) => {
                                        const associatedCriteria = 
                                            activeCriteria ? activeCriteria.find(x => x.filterColumn === group.ColumnName) : null
                                        const associatedActiveValue = associatedCriteria && associatedCriteria.activeValue ? associatedCriteria.activeValue : null 
                                        return (
                                            <div
                                                className={`innerBar
                                                ${
                                                    associatedActiveValue ? associatedActiveValue ===
                                                          d.label
                                                            ? 'selected'
                                                            : 'inactive'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    handleInnerDivClick(
                                                        group.ColumnName,
                                                        d.label
                                                    )
                                                }
                                                key={`${i}`}
                                                style={{
                                                    width: `${
                                                        (d.count /
                                                            barFilter.length) *
                                                        100
                                                    }%`,
                                                    minWidth: `4vw`,
                                                    backgroundColor:
                                                        filterColors[i],
                                                }}
                                            >
                                                <h6 className="is-size-6 has-text-black total">
                                                    {d.count}
                                                </h6>

                                                <h6 className="is-size-7 has-text-black">
                                                    {d.label}
                                                </h6>
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default AttributeBarFilters
