import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useMemo,
    useCallback,
} from 'react'
import { CurrentProcessContext } from './CurrentProcessContext'
import filterByAttributes from '../../utils/filterByAttributes'
import { getSubTypeConfigFromProcess } from '../../utils/subTypeConfig'

const FilterContext = createContext(null)

const FilterContextProvider = ({ children }) => {
    const [filters, setFilters] = useState({
        ActionGroup: null,
        StateName: null,
        Status: ['Low', 'Med', 'High', 'Overdue'],
        ProcessSubType: null,
    })
    const [temp, setTemp] = useState({})

    const [criteria, setCriteria] = useState({})
    const { externalDataItems, process, processSubTypeColumn } = useContext(
        CurrentProcessContext
    )

    const [filteredData, setFilteredData] = useState([])
    const [barFilter, setBarFilter] = useState([])
    const [attributeData, setAttributeData] = useState([])
    const { Status, ...noStatusFilters } = filters

    const subTypeData = useMemo(() => {
        if (
            externalDataItems &&
            externalDataItems.length &&
            filters &&
            !!filters.ProcessSubType
        ) {
            return externalDataItems.filter(
                (x) =>
                    x.AttributeJSON[processSubTypeColumn] ===
                    filters.ProcessSubType
            )
        } else {
            return externalDataItems
        }
    }, [externalDataItems, filters])

    const processConfig = useMemo(
        () => ({
            tooltipColumns: getSubTypeConfigFromProcess(
                process,
                filters,
                'ProcessTooltipColumns'
            ),
            filterColumns: getSubTypeConfigFromProcess(
                process,
                filters,
                'ProcessFilterColumns'
            ),
            reports: getSubTypeConfigFromProcess(
                process,
                filters,
                'ProcessReports'
            ),
            uiLabels: getSubTypeConfigFromProcess(
                process,
                filters,
                'ProcessUILabels'
            ),
            gridColumns: getSubTypeConfigFromProcess(
                process,
                filters,
                'ProcessGridColumns'
            ),
            attributeColumns: getSubTypeConfigFromProcess(
                process,
                filters,
                'ProcessAttributeBarFilters'
            ),
        }),
        [process, filters]
    )

    const statusCount = useMemo(() => {
        return subTypeData.filter((item) =>
            Object.keys(noStatusFilters).every((filter) => {
                const filt = filter === "ProcessSubType" ? processSubTypeColumn : filter
                return (
                    filters[filter] === null || filters[filter] === item[filt]
                )
            })
        )
    }, [noStatusFilters, subTypeData, processSubTypeColumn])

    const comboOptions = useMemo(() => {
        if (processConfig && processConfig.filterColumns) {
            return Object.keys(processConfig.filterColumns).filter(
                (filt) =>
                    processConfig.filterColumns[filt].ControlType === 'Combo'
            )
        } else {
            return []
        }
    }, [processConfig])

    const selectOptions = useMemo(() => {
        if (comboOptions && subTypeData.length) {
            const json = subTypeData.map((item) => item.AttributeJSON)
            return comboOptions.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr]: [...new Set(json.map((item) => item[curr]))].filter(
                        (item) => item !== ''
                    ),
                }),
                {}
            )
        } else return {}
    }, [subTypeData, comboOptions])

    const calcFilters = useCallback(() => {
        if (filters.Status.length) {
            setBarFilter(
                statusCount.filter((item) =>
                    filters.Status.includes(item.Status)
                )
            )
        } else {
            subTypeData.filter((item) =>
                Object.keys({ ...filters, Status: [] }).every( 
                    (filter) =>
                        filters[filter] === null ||
                        filters[filter] === item[filter] ||
                        filters[filter] ===
                            item.AttributeJSON[processSubTypeColumn]
                )
            )
        }
    }, [subTypeData, filters, processSubTypeColumn])

    const calcAttributes = useCallback(() => {
        // const attData = Object.values(filters).every((x) => x === null) || !barFilter.length ? externalDataItems : barFilter
        if (Object.keys(criteria).length && subTypeData.length) {
            setAttributeData(filterByAttributes(subTypeData, criteria))
        } else {
            setAttributeData([])
        }
    }, [criteria, subTypeData])

    useEffect(() => {
        calcFilters()
        calcAttributes()
    }, [filters, subTypeData, criteria])

    const handleClearAllFilters = (setClear) => {
        //clear CriteriaFilters
        setTemp({})
        setCriteria({})

        //clear BarFilters
        setFilters({ ActionGroup: null, StateName: null, Status: ['Low', 'Med', 'High', 'Overdue'] })
        setClear(true)
    }

    useEffect(() => {
        if (!attributeData.length) {
            setFilteredData(barFilter) //if there are ever no bar filters active there will be no data
        }
        if (attributeData.length) {
            setFilteredData(
                barFilter.filter((element) => attributeData.includes(element))
            )
        }
    }, [attributeData, barFilter, subTypeData])

    return (
        <FilterContext.Provider
            value={{
                filteredData,
                barFilter,
                setFilteredData,
                statusCount,
                criteria,
                setCriteria,
                filters,
                setFilters,
                selectOptions,
                subTypeData,
                processConfig,
                handleClearAllFilters,
                temp,
                setTemp,
            }}
        >
            {children}
        </FilterContext.Provider>
    )
}

export { FilterContext }
export default FilterContextProvider
