import { useEffect } from 'react';

export default function useKeyPress(trigger, key, action) {
  useEffect(() => {
    function onKeyup(e) {
        if (e.key === key)  {
          console.log(e)
          action()
      }
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [trigger]);
}