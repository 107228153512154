import React, {
    useMemo,
    useEffect,
    useState,
    useCallback,
    useRef,
    useContext,
} from 'react'
import {
    useTable,
    usePagination,
    useSortBy,
    useFilters,
    useExpanded,
    useRowSelect,
    useResizeColumns,
    useBlockLayout,
    useColumnOrder,
} from 'react-table'
import {
    FaAngleUp,
    FaAngleDown,
    FaAngleRight,
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaAngleLeft,
    FaSearch,
} from 'react-icons/fa'
import { StatusColors } from '../../../../utils/StatusColors'
import SubGridData from './SubGridData'
import GroupActionSelect from '../Actions/BatchActionSelect'
import ReportSelect from '../Reports/ReportSelect'
import {
    DefaultColumnFilter,
    fuzzyTextFilterFn,
} from '../../../elem/Grid/GridSearch'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import tableColumns from '../../../../utils/gridColumns'
import LoadingSpinner from '../../../elem/LoadingSpinner'
import GridControls from './GridControls'
import ColumnSelectionModal from '../../../elem/Grid/ColumnSelectionModal'
import { getTransitionsForExternalDataItem } from '../../../../utils/subTypeConfig'
import dayjs from 'dayjs'
import { getLayout } from '../../../../utils/localStorage/layouts'


const defaultColumn = () => ({
    Filter: DefaultColumnFilter,
    disableResizing: false,
    minWidth: 100,
    width: 160,
    maxWidth: 400,
})

const filterTypes = () => ({
    fuzzyText: fuzzyTextFilterFn,

    text: (rows, id, filterValue) => {
        return rows.filter((row) => {
            const rowValue = row.values[id]
            return rowValue !== undefined
                ? String(rowValue)
                      .toLowerCase()
                      .startsWith(String(filterValue).toLowerCase())
                : true
        })
    },
})

fuzzyTextFilterFn.autoRemove = (val) => !val

const RowCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
    )
})

const ProcessGrid = ({
    filteredData,
    transitions,
    setFilteredData,
    filters,
    actioned,
    setActioned,
    attributeColumns,
    loading,
    order,
    processSubTypeColumn,
    processId
}) => {
    const [newData, setNewData] = useState(filteredData)
    const [newRow, setNewRow] = useState(null)
    const skipPageResetRef = useRef()
    // const [tooltip, setTooltip] = useState(null)
    const [selectedRows, setSelectedRows] = useState([])
    const [hiddenColumns, setHiddenColumns] = useState([])
    const [customOrderFlag, setCustomOrderFlag] = useState(false)

    const { gridQuery, clear, setClear } = useContext(ParameterContext)
    const query = useMemo(
        () => (gridQuery.length ? gridQuery.map((x) => x.id) : []),
        [gridQuery]
    )

    const [visibleSearchBox, setVisibleSearchBox] = useState(query)
    const [cachedLayout, setCachedLayout] = useState({})


    const handleSearch = useCallback(
        (column) => {
            const id = column.id
            if (id) {
                visibleSearchBox.includes(id)
                    ? setVisibleSearchBox(
                          visibleSearchBox.filter((x) => x !== id)
                      )
                    : setVisibleSearchBox([...visibleSearchBox, id])
            }
        },
        [visibleSearchBox]
    )

    useEffect(() => {
        const updateLayout = async () => {
            const userLayout = await getLayout(processId)
                if (userLayout) {
                    setCachedLayout(userLayout)
                } 
        }
        updateLayout()
    }, [processId])

    useEffect(() => {
        if (clear) {
            setVisibleSearchBox([])
            setClear(false)
        }
    }, [clear])

    const dataRef = useRef(null)

    const [lastUpdate, setLastUpdate] = useState(
        new Date().toString().slice(4, 21)
    )
    const [groupActions, setGroupActions] = useState({})

    const updateRow = useCallback(
        (rowIndex, columnId, value) => {
            setFilteredData((old) =>
                old.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            [columnId]: value,
                        }
                    }
                    return row
                })
            )
        },
        [setFilteredData]
    )

    useEffect(() => {
        if (selectedRows.length) {
            const possibleTransitions = {
                Ids: selectedRows.map((row) => row.original.ExternalDataItemId),
                Actions: selectedRows
                    .map((row) =>
                        getTransitionsForExternalDataItem(
                            row,
                            processSubTypeColumn,
                            transitions
                        ).map((x) => x.ProcessStateTransitionId)
                    )
                    .reduce((acc, curr) => {
                        return acc.filter((item) => curr.includes(item))
                    }),
            }
            setGroupActions(possibleTransitions)
        } else {
            setGroupActions({})
        }
    }, [selectedRows])

    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <pre>
                <SubGridData
                    id={row.original.ExternalDataItemId}
                    setActioned={setActioned}
                />
            </pre>
        ),
        []
    )
    const columns = useMemo(
        () => tableColumns(attributeColumns, order, processSubTypeColumn, cachedLayout),
        [attributeColumns, order, processSubTypeColumn, cachedLayout]
    ) //customize process column order in utils/columnOrder

    const tableData = useMemo(() => filteredData, [filteredData])
    const total = useMemo(() => filteredData.length, [filteredData])

    const width = useMemo(
        () => ({
            columnWidths: columns.map((x) =>
                x.width ? `${x.width}px` : '60px'
            ),
        }),
        []
    )

    const refreshData = () => {
        dataRef.current = dataRef.current + 1
        setActioned(`ref_${dataRef.current}`)
        setLastUpdate(new Date().toString().slice(4, 21))
    }

    const {
        getTableProps,
        toggleAllRowsSelected,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        // rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        allColumns,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        setColumnOrder,
        // resetResizing,

        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
        {
            columns,
            autoResetPage: false,
            data: tableData,
            defaultColumn,
            filterTypes,
            autoResetSelectedRows: false,
            initialState: {
                pageIndex: 0,
                pageSize: 25,
                filters: gridQuery,
                gridLayout: width,
                hiddenColumns: columns.filter(col => col.defaultHidden === true).map(col => col.accessor)
            },
            autoResetSortBy: !skipPageResetRef.current,
            disableMultiSort: true,
            autoResetFilters: false,
            updateRow,
            sortTypes: React.useMemo(
                () => ({
                    caseinsensitive: (a, b, id) => {
                        const valueA =
                            a.values[id] !== null &&
                            typeof a.values[id] !== 'undefined'
                                ? a.values[id].toLowerCase()
                                : ''
                        const valueB =
                            b.values[id] !== null &&
                            typeof b.values[id] !== 'undefined'
                                ? b.values[id].toLowerCase()
                                : ''
                        return valueB > valueA ? -1 : valueB < valueA ? 1 : 0
                    },
                    date: (rowA, rowB, columnId) => {
                        const valueA = rowA.values[columnId]
                        const valueB = rowB.values[columnId]

                        const a = dayjs(valueA).toDate().getTime()
                        const b = dayjs(valueB).toDate().getTime()

                        return b > a ? -1 : b < a ? 1 : 0
                    },
                }),
                []
            ),
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useResizeColumns,
        useColumnOrder,
        useBlockLayout,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: 'selection',
                        groupByBoundary: true,
                        width: 35,
                        disableResizing: true,
                        Header: ({ getToggleAllPageRowsSelectedProps }) => (
                            <div style={{ paddingLeft: 0 }}>
                                <RowCheckbox
                                    {...getToggleAllPageRowsSelectedProps()}
                                />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <div>
                                <RowCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]
            })
        }
    )

    const filtRows = headerGroups[0].headers[0].filteredRows.length
        ? headerGroups[0].headers[0].filteredRows.map((x) => x.original)
        : null   
        
    useEffect(() => {
        setSelectedRows(selectedFlatRows)

        if (actioned !== newRow) {
            toggleAllRowsSelected(false)
            setSelectedRows([])
            setGroupActions({})
            setNewRow(actioned)
            setLastUpdate(new Date().toString().slice(4, 21))
        }
    }, [selectedRowIds, actioned])

    useEffect(() => {
        skipPageResetRef.current = true

        setNewData(filteredData)
        if (filteredData !== newData) {
            gotoPage(0)
            toggleAllRowsSelected(false)
            setSelectedRows([])
            setGroupActions({})
        }
    }, [filters])

    useEffect(() => {
        if (!customOrderFlag) {
            setColumnOrder(columns.map(x => x.accessor))            
        }
    }, [columns, setColumnOrder, customOrderFlag])

    const toggleHiddenColumns = (column) => {
        const colHiddenFieldProps = column.getToggleHiddenProps()
        
        if (colHiddenFieldProps.checked) {
            setHiddenColumns(current => ({
                [column.accessor]: colHiddenFieldProps.checked,
                ...current,
            }))   
        }
        else {

            delete hiddenColumns[column.accessor]
        }
    }
    return (
        <>
            <div className="aboveGrid">
                <GroupActionSelect
                    groupActions={groupActions}
                    transitions={transitions}
                />
                <ReportSelect
                    id={
                        selectedRows.length &&
                        selectedRows.every(
                            (x) =>
                                x.original.AttributeJSON.Operator ===
                                selectedRows[0].original.AttributeJSON.Operator
                        )
                            ? selectedRows.map(
                                  (x) => x.original.ExternalDataItemId
                              )
                            : null
                    }
                />
            </div>

            <div className="box gridWrapper">
                <GridControls
                    refreshData={refreshData}
                    filtRows={filtRows}
                    filteredData={filteredData}
                    lastUpdate={lastUpdate}
                />
                <div className="gridTop">
                    <div className="gridLoading">
                        {loading ? <LoadingSpinner /> : null}
                    </div>
                    <div className="pagination">
                        <span className="outer">
                            Page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value
                                        ? Number(e.target.value) - 1
                                        : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '50.5px' }}
                            />
                        </span>{' '}
                        <div className="pagDiv">
                            <button
                                className="arrow"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                <FaAngleDoubleLeft />
                            </button>{' '}
                            <button
                                className="arrow"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                <FaAngleLeft />
                            </button>{' '}
                            <span>
                                <strong className="is-size-6">
                                    Page {pageIndex + 1} of{' '}
                                    {!pageOptions.length
                                        ? 1
                                        : pageOptions.length}
                                </strong>{' '}
                            </span>
                            <button
                                className="arrow"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                <FaAngleRight />
                            </button>{' '}
                            <button
                                className="arrow"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                <FaAngleDoubleRight />
                            </button>{' '}
                        </div>
                        <div className="pagDiv outer">
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                <option key={total} value={total}>
                                    Show All
                                </option>

                                {[25, 50, 100, 500, 1000].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="showing">
                    <div style={{ marginLeft: '1%' }}>
                        {!filteredData.length
                            ? `Loading Grid Data`
                            : `Showing ${page.length} of ${
                                  filtRows
                                      ? filtRows.length
                                      : filteredData.length
                              } results`}
                    </div>
                </div>
                <ColumnSelectionModal
                    columns={allColumns}
                    setColumnOrder={setColumnOrder}
                    toggleHiddenColumns={toggleHiddenColumns}
                    setCustomOrderFlag={setCustomOrderFlag}
                    processId={processId}
                    cachedLayout={cachedLayout}
                    setCachedLayout={setCachedLayout}
                />
                <div
                    {...getTableProps()}
                    className="table is-hoverable resizable"
                >
                    <div>
                        {headerGroups.map((headerGroup) => (
                            <div
                                {...headerGroup.getHeaderGroupProps({
                                    // style: { paddingRight: '15px' },
                                })}
                                className="tr"
                            >
                                {headerGroup.headers.map((column) => {
                                    
                                    for (const [head, checked] of Object.entries(hiddenColumns)) {
                                        if (column.Header === head && checked === true) {
                                            column.toggleHidden()
                                        }
                                    }
                                    return (
                                    <div
                                        {...column.getHeaderProps()}
                                        className="th"
                                    >
                                        <div className="header">
                                            {column.canFilter ? (
                                                <div
                                                    className="is-size-7 searchIcon"
                                                    onClick={() =>
                                                        handleSearch(column)
                                                    }
                                                >
                                                    <FaSearch />
                                                </div>
                                            ) : null}
                                            <span
                                                {...column.getSortByToggleProps()}
                                            >
                                                {column.render('Header')}
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <FaAngleUp />
                                                    ) : (
                                                        <FaAngleDown />
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </div>
                                        {column.canResize && (
                                            <div
                                                {...column.getResizerProps()}
                                                className={`resizer ${
                                                    column.isResizing
                                                        ? 'isResizing'
                                                        : ''
                                                }`}
                                            />
                                        )}
                                        <div
                                            style={
                                                visibleSearchBox.length &&
                                                visibleSearchBox.indexOf(
                                                    column.id
                                                ) > -1
                                                    ? null
                                                    : { display: 'none' }
                                            }
                                        >
                                            {column.canFilter
                                                ? column.render('Filter')
                                                : null}
                                        </div>
                                    </div>
                                )
                                })}
                            </div>
                        ))}
                    </div>
                    <div className="tbody tableOverflowSize">
                        {page.map((row) => {
                            prepareRow(row)
                            const status = row.original.Status
                            return (
                                <React.Fragment key={row.id}>
                                    <div {...row.getRowProps()} className="tr">
                                        {row.cells.map((cell, idx) => {
                                            const td = cell.getCellProps()
                                            return (
                                                <div
                                                    key={idx}
                                                    {...td}
                                                    style={{
                                                        ...td.style,
                                                        color:
                                                            StatusColors[
                                                                status
                                                            ],
                                                        position: 'relative',
                                                    }}
                                                    className="td"
                                                >
                                                    {cell.render('Cell')}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {row.isExpanded ? (
                                        <div className="tr">
                                            <div
                                                className="td"
                                                colSpan={visibleColumns.length}
                                                style={{ position: 'relative' }}
                                            >
                                                {renderRowSubComponent({ row })}
                                            </div>
                                        </div>
                                    ) : null}
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProcessGrid
